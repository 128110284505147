import React, { useEffect, useState } from "react";
import { Container, Form, Col, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import logo from "../assets/logo.png";
import { useDispatch } from "react-redux";
import { loginDetail } from "../component/redux/action/login";
import { loadData } from "../component/redux/action/TodatData";
import { messages } from "../component/common/notification";
import { Toaster } from "react-hot-toast";
import smardenLogo from "../assets/SmarDen Logo.png";
import { images } from "../component/common/images";
import { common } from "@mui/material/colors";
export const user = [
  {
    userName: "Admin",
    password: "admin",
    deviceId: [
      { deviceId: "M240003", name: "M240003" },
      { deviceId: "M240001", name: "M240001" },
    ],
  },
  {
    userName: "habitat",
    password: "password",
    deviceId: [
      {
        deviceId: "M250001",
        name: "M250001",
        image: images.ac,
        showName: "Outdoor AC",
      },
      {
        deviceId: "M240002",
        name: "M240002",
        image: images.ac,
        showName: "Common Area Ac",
      },
      {
        deviceId: "M280024",
        name: "M280024",
        image: images.ac,
        showName: "Cabin AC",
      },
    ],
  },
  {
    userName: "wiley",
    password: "password",
    cpu: 9.5,
    deviceId: [
      { deviceId: "M240007", name: "M240007" },
      { deviceId: "M240008", name: "M240008" },
    ],
  },
  {
    userName: "abl",
    password: "password",
    deviceId: [{ deviceId: "M240009", name: "M240009" }],
  },
  {
    userName: "smarden",
    password: "password",
    deviceId: [
      { deviceId: "M240021", name: "M240021" },
      { deviceId: "M240188", name: "M240188" }
    ],
    logo: smardenLogo,
    style: { width: "110.304px" },
  },
  {
    userName: "aliste",
    password: "aliste",
    deviceId: [
      { deviceId: "M240014", name: "M240014" },
      { deviceId: "M240011", name: "M240011" },
      { deviceId: "M240015", name: "M240015" },
      { deviceId: "M240016", name: "M240016" },
      { deviceId: "M240003", name: "M240003" },
      { deviceId: "M240001", name: "M240001" },
      { deviceId: "M240002", name: "M240002" },
      { deviceId: "M250001", name: "M250001" },
      { deviceId: "M240007", name: "M240007" },
      { deviceId: "M240008", name: "M240008" },
      { deviceId: "M240009", name: "M240009" },
      { deviceId: "M240017", name: "M240017" },
    ],
  },
  {
    userName: "laffaire",
    password: "password",
    deviceId: [
      { deviceId: "M240030", name: "101", image: images.ac, showName: "101" },
      { deviceId: "M240016", name: "102", image: images.ac, showName: "102" },
      { deviceId: "M240052", name: "103", image: images.ac, showName: "103" },
      { deviceId: "M240017", name: "104", image: images.ac, showName: "104" },
      { deviceId: "M240022", name: "105", image: images.ac, showName: "105" },
      { deviceId: "M240013", name: "106", image: images.ac, showName: "106" },
      { deviceId: "M240050", name: "107", image: images.ac, showName: "107" },
      { deviceId: "M240024", name: "108", image: images.ac, showName: "108" },
      { deviceId: "M240014", name: "109", image: images.ac, showName: "109" },
      { deviceId: "M240035", name: "201", image: images.ac, showName: "201" },
      { deviceId: "M240020", name: "202", image: images.ac, showName: "202" },
      { deviceId: "M240041", name: "203", image: images.ac, showName: "203" },
      { deviceId: "M240036", name: "204", image: images.ac, showName: "204" },
      { deviceId: "M240049", name: "205", image: images.ac, showName: "205" },
      { deviceId: "M240011", name: "206", image: images.ac, showName: "206" },
      { deviceId: "M240033", name: "207", image: images.ac, showName: "207" },
      { deviceId: "M240027", name: "208", image: images.ac, showName: "208" },
      { deviceId: "M240045", name: "209", image: images.ac, showName: "209" },
      { deviceId: "M240047", name: "210", image: images.ac, showName: "210" },
      { deviceId: "M240055", name: "301", image: images.ac, showName: "301" },
      { deviceId: "M240037", name: "302", image: images.ac, showName: "302" },
      { deviceId: "M240042", name: "303", image: images.ac, showName: "303" },
      { deviceId: "M240056", name: "304", image: images.ac, showName: "304" },
      { deviceId: "M240058", name: "305", image: images.ac, showName: "305" },
      { deviceId: "M240044", name: "306", image: images.ac, showName: "306" },
      { deviceId: "M240054", name: "307", image: images.ac, showName: "307" },
      { deviceId: "M240040", name: "308", image: images.ac, showName: "308" },
      { deviceId: "M240010", name: "309", image: images.ac, showName: "309" },
      { deviceId: "M240015", name: "310", image: images.ac, showName: "310" },
    ],
  },
  {
    userName: "yourspace",
    password: "password",
    deviceId: [
      {
        deviceId: "M240026",
        name: "M240026",
        image: images.ac,
        showName: "Common AC",
      },
      {
        deviceId: "M280014",
        name: "M280014",
        image: images.geyser,
        showName: "Geyser 1",
        metered: false,
      },
      {
        deviceId: "M280011",
        name: "M280011",
        image: images.geyser,
        showName: "Geyser 2",
        metered: false,
      },
      {
        deviceId: "M280010",
        name: "M280010",
        image: images.ac,
        showName: "AC 1",
        metered: false,
      },
      {
        deviceId: "M280013",
        name: "M280013",
        image: images.ac,
        showName: "AC 2",
        metered: false,
      },
    ],
  },
  {
    userName: "tribespace",
    password: "password",
    deviceId: [
      { deviceId: "M240019", name: "M240019" },
      { deviceId: "M240020", name: "M240020" },
    ],
  },
  {
    userName: "campus",
    password: "password",
    deviceId: [
      {
        deviceId: "M240025",
        name: "Light",
        image: images.blub,
        showName: "Light",
        active: true,
      },
      { deviceId: "M240028", name: "AC", image: images.ac, showName: "AC" },
      { deviceId: "M240046", name: "AC", image: images.ac, showName: "AC" },
    ],
  },
  {
    userName: "oceans",
    password: "password",
    cpu: 9.5,
    deviceId: [
      { deviceId: "M240063", name: "M240063" },
      { deviceId: "M240064", name: "M240064" },
    ],
  },
  {
    userName: "vietnom",
    password: "password",
    deviceId: [
      { deviceId: "M250006", name: "M250006" },
      { deviceId: "M250005", name: "M250005" },
      { deviceId: "M250010", name: "M250010" },
    ],
  },
  {
    userName: "paras",
    password: "password",
    deviceId: [
      {
        deviceId: "M280029",
        name: "M280029",
        image: images.ac,
        showName: "Room - 307",
      },
      {
        deviceId: "M280022",
        name: "M280022",
        image: images.ac,
        showName: "Room - 202",
      },
    ],
  },
  {
    userName: "bata",
    password: "password",
    cpu: 6.4,
    powerMuliplyier: 2.5,
    deviceId: [{ deviceId: "M250008", name: "M250008" }],
  },
  {
    userName: "grandcentral",
    password: "password",
    deviceId: [
      {
        deviceId: "M280043",
        name: "101",
        image: images.ac,
        showName: "Room - 101",
      },
      {
        deviceId: "M280035",
        name: "102",
        image: images.ac,
        showName: "Room - 102",
      },
      {
        deviceId: "M280021",
        name: "103",
        image: images.ac,
        showName: "Room - 103",
      },
      {
        deviceId: "M280038",
        name: "104",
        image: images.ac,
        showName: "Room - 104",
      },
      {
        deviceId: "M280037",
        name: "105",
        image: images.ac,
        showName: "Room - 105",
      },
      {
        deviceId: "M280036",
        name: "106",
        image: images.ac,
        showName: "Room - 106",
      },
      {
        deviceId: "M280020",
        name: "107",
        image: images.ac,
        showName: "Room - 107",
      },
      {
        deviceId: "M280044",
        name: "108",
        image: images.ac,
        showName: "Room - 108",
      },
      {
        deviceId: "M280030",
        name: "201",
        image: images.ac,
        showName: "Room - 201",
      },
      {
        deviceId: "M280027",
        name: "202",
        image: images.ac,
        showName: "Room - 202",
      },
      {
        deviceId: "M280016",
        name: "203",
        image: images.ac,
        showName: "Room - 203",
      },
      {
        deviceId: "M280017",
        name: "204",
        image: images.ac,
        showName: "Room - 204",
      },
      {
        deviceId: "M280042",
        name: "205",
        image: images.ac,
        showName: "Room - 205",
      },
      {
        deviceId: "M280003",
        name: "206",
        image: images.ac,
        showName: "Room - 206",
      },
      {
        deviceId: "M280032",
        name: "207",
        image: images.ac,
        showName: "Room - 207",
      },
      {
        deviceId: "M280028",
        name: "208",
        image: images.ac,
        showName: "Room - 208",
      },
      {
        deviceId: "M280059",
        name: "209",
        image: images.ac,
        showName: "Room - 209",
      },
      {
        deviceId: "M280025",
        name: "301",
        image: images.ac,
        showName: "Room - 301",
      },
      {
        deviceId: "M280039",
        name: "302",
        image: images.ac,
        showName: "Room - 302",
      },
      {
        deviceId: "M280031",
        name: "303",
        image: images.ac,
        showName: "Room - 303",
      },
      {
        deviceId: "M280023",
        name: "304",
        image: images.ac,
        showName: "Room - 304",
      },
      {
        deviceId: "M280041",
        name: "305",
        image: images.ac,
        showName: "Room - 305",
      },
      {
        deviceId: "M280033",
        name: "306",
        image: images.ac,
        showName: "Room - 306",
      },
      {
        deviceId: "M280005",
        name: "307",
        image: images.ac,
        showName: "Room - 307",
      },
      {
        deviceId: "M280040",
        name: "308",
        image: images.ac,
        showName: "Room - 308",
      },
      {
        deviceId: "M280006",
        name: "309",
        image: images.ac,
        showName: "Room - 309",
      },
    ],
  },
  {
    userName: "steelo",
    password: "password",
    deviceId: [{ deviceId: "M240115", name: "M240115" }],
  },
  {
    userName: "sarthakpalace",
    password: "password",
    deviceId: [
      { deviceId: "M240097", name: "103" },
      { deviceId: "M240114", name: "107" },
    ],
  },
  {
    userName: "alankaram",
    password: "password",
    deviceId: [
      { deviceId: "M280048", name: "M280048" },
      { deviceId: "M280069", name: "M280069" },
      { deviceId: "M280063", name: "M280063" },
      { deviceId: "M280052", name: "M280052" },
      { deviceId: "M280066", name: "M280066" },
      { deviceId: "M280046", name: "M280046" },
      { deviceId: "M280067", name: "M280067" },
      { deviceId: "M280064", name: "M280064" },
      { deviceId: "M280056", name: "M280056" },
      { deviceId: "M280058", name: "M280058" },
      { deviceId: "M280057", name: "M280057" },
      { deviceId: "M280055", name: "M280055" },
      { deviceId: "M280060", name: "M280060" },
      { deviceId: "M280049", name: "M280049" },
      { deviceId: "M280054", name: "M280054" },
      { deviceId: "M280047", name: "M280047" },
      { deviceId: "M280053", name: "M280053" },
      { deviceId: "M280045", name: "M280045" },
      { deviceId: "M280050", name: "M280050" },
      { deviceId: "M280062", name: "M280062" },
      { deviceId: "M280065", name: "M280065" },
      { deviceId: "M280059", name: "M280059" },
      { deviceId: "M2400443", name: "M240043" },
    ],
  },
  {
    userName: "ARS",
    password: "password",
    deviceId: [{ deviceId: "M240071", name: "M240071" }],
  },
  {
    userName: "campus2",
    password: "password",
    deviceId: [
      { deviceId: "M240107", name: "AC 1", image: images.ac, showName: "AC 1" },
      { deviceId: "M240106", name: "AC 2", image: images.ac, showName: "AC 2" },
      {
        deviceId: "M240120",
        name: "Light",
        image: images.blub,
        showName: "Light",
        active: true,
      },
    ],
  },
  {
    userName: "rajiv",
    password: "password",
    deviceId: [
      { deviceId: "M240103", name: "M240103" },
      { deviceId: "M240099", name: "M240099" },
      { deviceId: "M240067", name: "M240067" },
      { deviceId: "M240079", name: "M240079" },
      { deviceId: "M240108", name: "M240108" },
    ],
  },
  {
    userName: "bikanerwala",
    password: "password",
    graph: true,
    random: true,
    deviceId: [
      { deviceId: "M250067", name: "AC 1", image: images.ac, showName: "AC 1" },
      { deviceId: "M250007", name: "AC 2", image: images.ac, showName: "AC 2" },
      { deviceId: "M250016", name: "AC 3", image: images.ac, showName: "AC 3" },
      { deviceId: "M250019", name: "AC 4", image: images.ac, showName: "AC 4" },
      { deviceId: "M250062", name: "AC 5", image: images.ac, showName: "AC 5" },
      { deviceId: "M240206", name: "AC 6", image: images.ac, showName: "AC 6" },
      { deviceId: "M240205", name: "AC 7", image: images.ac, showName: "AC 7" },
      { deviceId: "M250072", name: "AC 8", image: images.ac, showName: "AC 8" },
      //  {deviceId:'M250015',name:"",image:images.mcb,showName:'1st MCB Box',phase:true},
      // {deviceId:'M250047',name:"2nd MCB Box",image:images.mcb,showName:'2nd MCB Box',phase:true},
      {
        deviceId: "M250049",
        name: "1st MCB Box",
        image: images.mcb,
        showName: "1st MCB Box",
        phase: true,
      },
      {
        deviceId: "M250074",
        name: "2nd MCB Box",
        image: images.mcb,
        showName: "2nd MCB Box",
        phase: true,
      },
      {
        deviceId: "M250073",
        name: "3rd MCB Box",
        image: images.mcb,
        showName: "3rd MCB Box",
        phase: true,
      },
      {
        deviceId: "M250055",
        name: "4th MCB Box",
        image: images.mcb,
        showName: "4th MCB Box",
        phase: true,
      },
      {
        deviceId: "M250076",
        name: "5th MCB Box",
        image: images.mcb,
        showName: "5th MCB Box",
        phase: true,
      },
      {
        deviceId: "M250046",
        name: "6th MCB Box",
        image: images.mcb,
        showName: "6th MCB Box",
        phase: true,
      },
    ],
  },
  {
    userName: "demo",
    password: "password",
    deviceId: [
      { deviceId: "M250008", name: "M250008" },
      { deviceId: "M280024", name: "M280024" },
      { deviceId: "M240025", name: "M240025" },
      { deviceId: "M240103", name: "M240103" },
      { deviceId: "M240099", name: "M240099" },
      { deviceId: "M240079", name: "M240079" },
    ],
  },
  {
    userName: "stanza",
    password: "password",
    deviceId: [
      { deviceId: "M320007", name: "M320007" },
      { deviceId: "M320011", name: "M320011" },
      { deviceId: "M320018", name: "M320018" },
      { deviceId: "M320015", name: "M320015" },
      { deviceId: "M320003", name: "M320003" },
      { deviceId: "M320020", name: "M320020" },
    ],
  },
  {
    userName: "chetanya",
    password: "password",
    deviceId: [
      { deviceId: "M240109", name: "M240109" },
      { deviceId: "M240116", name: "M240116" },
    ],
  },
  {
    userName: "rishankDabra",
    password: "password",
    cpu: 5.5,
    deviceId: [{ deviceId: "M240052", name: "M240052" }],
  },
  {
    userName: "bikaner",
    password: "password",
    link: "",
    graph: false,
    deviceId: [{ deviceId: "M250015", name: "M250015" }],
  },
  {
    userName: "rahulmuskan",
    password: "password",
    deviceId: [
      { deviceId: "M240048", name: "M240048" },
      { deviceId: "M240101", name: "M240101" },
    ],
  },
  {
    userName: "rajkamalPlywood",
    password: "password",
    deviceId: [
      { deviceId: "M280232", name: "M280232" },
      { deviceId: "M280182", name: "M280182" },
      { deviceId: "M280289", name: "M280289" },
      { deviceId: "M280288", name: "M280288" },
      { deviceId: "M280187", name: "M280187" },
      { deviceId: "M280189", name: "M280189" },
      { deviceId: "M280073", name: "M280073" },
      { deviceId: "M280296", name: "M280296" },
      { deviceId: "M280205", name: "M280205" },
      { deviceId: "M280284", name: "M280284" },
    ],
  },
  {
    userName: "nulifeHospital",
    password: "password",
    deviceId: [
      { deviceId: "M240027", name: "M240027" },
      { deviceId: "M240078", name: "M240078" },
      { deviceId: "M280215", name: "M280215" },
      { deviceId: "M280258", name: "M280258" },
      { deviceId: "M240070", name: "M240070" },
    ],
  },
  {
    userName: "hansHospital",
    password: "password",
    deviceId: [
      { deviceId: "M240024", name: "M240024" },
      { deviceId: "M240040", name: "M240040" },
    ],
  },
  {
    userName: "HOTEL ROSEMAN",
    password: "password",
    deviceId: [
      { deviceId: "M280294", name: "M280294" },
      { deviceId: "M280019", name: "M280019" },
    ],
  },
  {
    userName: "Aliste3",
    password: "password",
    link: "",
    graph: true,
    deviceId: [{ deviceId: "M250018", name: "M250018" }],
  },
  {
    userName: "Raman Kumar",
    password: "password",
    cpu: 7,
    deviceId: [
      { deviceId: "M280117", name: "AC", image: images.ac, showName: "AC" },
      {
        deviceId: "M280285",
        name: "AC",
        image: images.ac,
        showName: "AC Kids Room",
      },
    ],
  },
  {
    userName: "Sachin Kumar",
    password: "password",
    deviceId: [
      { deviceId: "M250020", name: "AC", image: images.ac, showName: "AC" },
    ],
  },
  {
    userName: "Royal Orchid",
    password: "password",
    deviceId: [
      { deviceId: "M280034", name: "M280034" },
      { deviceId: "M280008", name: "M280008" },
    ],
  },
  {
    userName: "Steelo Brite",
    password: "password",
    deviceId: [{ deviceId: "M250058", name: "M250058" }],
  },
  {
    userName: "test",
    password: "password",
    graph: true,
    deviceId: [
      { deviceId: "M250018", name: "M250018" },
      { deviceId: "M250028", name: "M250028" },
      {
        deviceId: "M280024",
        name: "M280024",
        image: images.ac,
        showName: "Cabin AC",
      },
    ],
  },
  {
    userName: "bata.bengaluru",
    password: "password",
    cpu: 8,
    powerMuliplyier: 2.5,
    deviceId: [
      {
        deviceId: "M240025",
        name: "Light",
        image: images.blub,
        showName: "Light",
        active: true,
      },
      { deviceId: "M250050", name: "M250050" },
      { deviceId: "M250041", name: "M250041" },
      { deviceId: "M250024", name: "M250024" },
      { deviceId: "M250059", name: "M250059" },
    ],
  },
  {
    userName: "careerlauncher",
    password: "password",
    graph: true,
    deviceId: [
      {
        deviceId: "M240160",
        name: "Studio",
        image: images.ac,
        showName: "Studio",
      },
      {
        deviceId: "M240044",
        name: "Pantry",
        image: images.ac,
        showName: "Pantry",
      },
      {
        deviceId: "M240013",
        name: "Silent Zone",
        image: images.ac,
        showName: "Silent Zone",
      },
      {
        deviceId: "M240022",
        name: "Satya Narayan",
        image: images.ac,
        showName: "Satya Narayan",
      },
      {
        deviceId: "M240054",
        name: "Nikhil Sir Cabin",
        image: images.ac,
        showName: "Nikhil Sir Cabin",
      },
      {
        deviceId: "M240076",
        name: "Gautam Puri",
        image: images.ac,
        showName: "Gautam Puri",
      },
      {
        deviceId: "M240072",
        name: "R. Sriniwasan",
        image: images.ac,
        showName: "R. Sriniwasan",
      },
      {
        deviceId: "M240015",
        name: "Sujit Bhattacharya",
        image: images.ac,
        showName: "Sujit Bhattacharya",
      },
      {
        deviceId: "M240017",
        name: "Sujatha Kshirsagar",
        image: images.ac,
        showName: "Sujatha Kshirsagar",
      },
      {
        deviceId: "M240214",
        name: "Shiv Kumar",
        image: images.ac,
        showName: "Shiv Kumar",
      },
      {
        deviceId: "M240134",
        name: "Vinod",
        image: images.ac,
        showName: "Vinod",
      },
      {
        deviceId: "M240056",
        name: "Aashish sir + LG AC",
        image: images.ac,
        showName: "Aashish sir + LG AC",
      },
      {
        deviceId: "M250022",
        name: "Duct 6",
        image: images.ac,
        showName: "Duct 6",
      },
      {
        deviceId: "M250021",
        name: "Duct 3",
        image: images.ac,
        showName: "Duct 3",
      },
      {
        deviceId: "M250068",
        name: "Duct 1",
        image: images.ac,
        showName: "Duct 1",
      },
      {
        deviceId: "M250047",
        name: "Duct 4",
        image: images.ac,
        showName: "Duct 4",
      },
      {
        deviceId: "M250053",
        name: "Duct 7",
        image: images.ac,
        showName: "Duct 7",
      },
      {
        deviceId: "M250075",
        name: "Duct 5",
        image: images.ac,
        showName: "Duct 5",
      },
      {
        deviceId: "M250069",
        name: "Duct 2",
        image: images.ac,
        showName: "Duct 2",
      },
      { deviceId: "M240014", name: "AC", image: images.ac, showName: "AC" },
      {
        deviceId: "M240050",
        name: "Meeting Room 1",
        image: images.ac,
        showName: "Meeting Room 1",
      },
      {
        deviceId: "M240045",
        name: "Arjun Wadhwa and Duct 2",
        image: images.ac,
        showName: "Arjun Wadhwa and Duct 2",
      },
      { deviceId: "M240071", name: "AC", image: images.ac, showName: "AC" },
      {
        deviceId: "M240069",
        name: "Meeting Room 2",
        image: images.ac,
        showName: "Meeting Room 2",
      },
      { deviceId: "M250048", name: "AC 1", image: images.ac, showName: "AC 1" },
      { deviceId: "M250045", name: "AC 2", image: images.ac, showName: "AC 2" },
    ],
  },
  {
    userName: "9711697119",
    password: "garg inn",
    deviceId: [
      { deviceId: "M280153", name: "101", image: images.ac, showName: "101" },
      { deviceId: "M280137", name: "301", image: images.ac, showName: "301" },
      { deviceId: "M280124", name: "302", image: images.ac, showName: "302" },
      { deviceId: "M280168", name: "504", image: images.ac, showName: "504" },
      { deviceId: "M280118", name: "502", image: images.ac, showName: "502" },
      { deviceId: "M280120", name: "305", image: images.ac, showName: "305" },
      { deviceId: "M280094", name: "402", image: images.ac, showName: "402" },
      { deviceId: "M280097", name: "304", image: images.ac, showName: "304" },
      { deviceId: "M280143", name: "405", image: images.ac, showName: "405" },
      { deviceId: "M280201", name: "503", image: images.ac, showName: "503" },
      { deviceId: "M280114", name: "303", image: images.ac, showName: "303" },
      { deviceId: "M280177", name: "501", image: images.ac, showName: "501" },
      { deviceId: "M280195", name: "501", image: images.ac, showName: "501" },
      { deviceId: "M280116", name: "201", image: images.ac, showName: "201" },
      { deviceId: "M280155", name: "401", image: images.ac, showName: "401" },
      { deviceId: "M280160", name: "404", image: images.ac, showName: "404" },
    ],
  },
  {
    userName: "veggulati",
    password: "password",
    deviceId: [
      { deviceId: "M250034", name: "AC 1", showName: "AC 1" },
      { deviceId: "M250037", name: "AC 2", showName: "AC 2" },
      { deviceId: "M250061", name: "AC 3", showName: "AC 3" },
      { deviceId: "M250056", name: "AC 4", showName: "AC 4" },
      { deviceId: "M240250", name: "refrigerator", showName: "refrigerator" },
      { deviceId: "M240282", name: "Freezer", showName: "Freezer" },
      { deviceId: "M240210", name: "Softy machine", showName: "Softy machine" },
    ],
  },
  {
    userName: "krishna novelties",
    password: "password",
    deviceId: [{ deviceId: "M240289", name: "M240289" }],
  },
  {
    userName: "Suman Mittal",
    password: "password",
    deviceId: [
      {
        deviceId: "M240076",
        name: "Ground floor",
        showName: "Ground floor",
      },
      { deviceId: "M240054", name: "Office", showName: "Office" },
      {
        deviceId: "M240022",
        name: "Fist floor 1",
        showName: "Fist floor 1",
      },
      {
        deviceId: "M240013",
        name: "Fist floor 2",
        showName: "Fist floor 2",
      },
      {
        deviceId: "M240017",
        name: "Second floor 2",
        showName: "Second floor 2",
      },
      {
        deviceId: "M240056",
        name: "Second floor 1",
        showName: "Second floor 1",
      },
    ],
  },
  {
    userName: "Maninder",
    password: "password",
    deviceId: [
      { deviceId: "M240296", name: "M240296", showName: "M240296" },
      { deviceId: "M240119", name: "Paler 1 ", showName: "Paler 1 " },
      { deviceId: "M240065", name: "Paler 2", showName: "Paler 2" },
      {
        deviceId: "M240219",
        name: "Faluda miter ",
        showName: "Faluda miter ",
      },
    ],
  },
  {
    userName: "platinuminn",
    password: "password",
    deviceId: [
      { deviceId: "M280197", name: "M280197", showName: "308" },
      { deviceId: "M280103", name: "M280103", showName: "303" },
      { deviceId: "M280278", name: "M280278", showName: "312" },
      { deviceId: "M280184", name: "M280184", showName: "410" },
      { deviceId: "M280112", name: "M280112", showName: "409" },
      { deviceId: "M280123", name: "M280123", showName: "307" },
      { deviceId: "M280164", name: "M280164", showName: "309" },
      { deviceId: "M280179", name: "M280179", showName: "310" },
      { deviceId: "M280286", name: "M280286", showName: "201" },
      { deviceId: "M280245", name: "M280245", showName: "202" },
      { deviceId: "M280210", name: "M280210", showName: "204" },
      { deviceId: "M280238", name: "M280238", showName: "205" },
      { deviceId: "M280183", name: "M280183", showName: "206" },
      { deviceId: "M280277", name: "M280277", showName: "203" },
      { deviceId: "M280213", name: "M280213", showName: "212" },
      { deviceId: "M280225", name: "M280225", showName: "211" },
      { deviceId: "M280259", name: "M280259", showName: "210" },
      { deviceId: "M280237", name: "M280237", showName: "209" },
      { deviceId: "M280234", name: "M280234", showName: "208" },
      { deviceId: "M280129", name: "M280129", showName: "311" },
      { deviceId: "M280227", name: "M280227", showName: "305" },
      { deviceId: "M280230", name: "M280230", showName: "405" },
      { deviceId: "M280257", name: "M280257", showName: "404" },
      { deviceId: "M280208", name: "M280208", showName: "402" },
      { deviceId: "M280212", name: "M280212", showName: "407" },
      { deviceId: "M280174", name: "M280174", showName: "403" },
      { deviceId: "M280219", name: "M280219", showName: "408" },
      { deviceId: "M280226", name: "M280226", showName: "301" },
      { deviceId: "M280211", name: "M280211", showName: "401" },
      { deviceId: "M280280", name: "M280280", showName: "406" },
      { deviceId: "M280134", name: "M280134", showName: "306" },
      { deviceId: "M280269", name: "M280269", showName: "411" },
      { deviceId: "M280236", name: "M280236", showName: "412" },
      { deviceId: "M280279", name: "M280279", showName: "302" },
      { deviceId: "M280275", name: "M280275", showName: "304" },
    ],
  },
  {
    userName: "platinumresidency",
    password: "password",
    deviceId: [
      { deviceId: "M280261", name: "M280261", showName: "407" },
      { deviceId: "M280254", name: "M280254", showName: "409" },
      { deviceId: "M280223", name: "M280223", showName: "405" },
      { deviceId: "M280241", name: "M280241", showName: "406" },
      { deviceId: "M280222", name: "M280222", showName: "410" },
      { deviceId: "M280265", name: "M280265", showName: "309" },
      { deviceId: "M280207", name: "M280207", showName: "310" },
      { deviceId: "M280251", name: "M280251", showName: "308" },
      { deviceId: "M280248", name: "M280248", showName: "307" },
      { deviceId: "M280224", name: "M280224", showName: "302" },
      { deviceId: "M280272", name: "M280272", showName: "303" },
      { deviceId: "M280256", name: "M280256", showName: "301" },
      { deviceId: "M280186", name: "M280186", showName: "304" },
      { deviceId: "M280274", name: "M280274", showName: "306" },
      { deviceId: "M280200", name: "M280200", showName: "305" },
      { deviceId: "M280266", name: "M280266", showName: "404" },
      { deviceId: "M280290", name: "M280290", showName: "Server Room AC" },
      { deviceId: "M280209", name: "M280209", showName: "403" },
      { deviceId: "M280194", name: "M280194", showName: "402" },
      { deviceId: "M280268", name: "M280268", showName: "408" },
      { deviceId: "M280270", name: "M280270", showName: "401" },
    ],
  },
  {
    userName: "Settl Verona",
    password: "password",
    deviceId: [
      { deviceId: "M240208", name: "M240208", showName: "001" },
      { deviceId: "M240217", name: "M240217", showName: "002" },
      { deviceId: "M240145", name: "M240145", showName: "003" },
      { deviceId: "M240156", name: "M240156", showName: "004" },
      { deviceId: "M240212", name: "M240212", showName: "005" },
      { deviceId: "M240164", name: "M240164", showName: "006" },
      { deviceId: "M240197", name: "M240197", showName: "101" },
      { deviceId: "M240171", name: "M240171", showName: "103" },
      { deviceId: "M240159", name: "M240159", showName: "104" },
      { deviceId: "M240207", name: "M240207", showName: "105" },
      { deviceId: "M280158", name: "M280158", showName: "107" },
      { deviceId: "M240173", name: "M240173", showName: "109" },
      { deviceId: "M240224", name: "M240224", showName: "110" },
      { deviceId: "M280152", name: "M280152", showName: "110" },
      { deviceId: "M240228", name: "M240228", showName: "111" },
      { deviceId: "M240170", name: "M240170", showName: "112" },
      { deviceId: "M240166", name: "M240166", showName: "113" },
      { deviceId: "M240184", name: "M240184", showName: "114" },
      { deviceId: "M240155", name: "M240155", showName: "114" },
      { deviceId: "M240168", name: "M240168", showName: "115" },
      { deviceId: "M240176", name: "M240176", showName: "201" },
      { deviceId: "M240180", name: "M240180", showName: "202" },
      { deviceId: "M240154", name: "M240154", showName: "203" },
      { deviceId: "M240149", name: "M240149", showName: "204" },
      { deviceId: "M240189", name: "M240189", showName: "205" },
      { deviceId: "M240222", name: "M240222", showName: "206" },
      { deviceId: "M240193", name: "M240193", showName: "207" },
      { deviceId: "M240213", name: "M240213", showName: "208" },
      { deviceId: "M240201", name: "M240201", showName: "209" },
      { deviceId: "M240185", name: "M240185", showName: "211" },
      { deviceId: "M240323", name: "Room no 414", showName: "212" },
      { deviceId: "M240181", name: "M240181", showName: "213" },
      { deviceId: "M240174", name: "M240174", showName: "214" },
      { deviceId: "M240235", name: "M240235", showName: "215" },
      { deviceId: "M240158", name: "M240158", showName: "301" },
      { deviceId: "M240172", name: "M240172", showName: "302" },
      { deviceId: "M240177", name: "M240177", showName: "302" },
      { deviceId: "M240218", name: "M240218", showName: "303" },
      { deviceId: "M240204", name: "M240204", showName: "304" },
      { deviceId: "M240161", name: "M240161", showName: "305" },
      { deviceId: "M240144", name: "M240144", showName: "306" },
      { deviceId: "M240223", name: "M240223", showName: "307" },
      { deviceId: "M240221", name: "M240221", showName: "308" },
      { deviceId: "M240162", name: "M240162", showName: "309" },
      { deviceId: "M240310", name: "M240310", showName: "310" },
      { deviceId: "M240285", name: "M240285", showName: "311" },
      { deviceId: "M240281", name: "M240281", showName: "312" },
      { deviceId: "M240165", name: "M240165", showName: "313" },
      { deviceId: "M240284", name: "M240284", showName: "314" },
      { deviceId: "M240150", name: "M240150", showName: "315" },
      { deviceId: "M240265", name: "M240265", showName: "401" },
      { deviceId: "M240234", name: "M240234", showName: "402" },
      { deviceId: "M240236", name: "M240236", showName: "403" },
      { deviceId: "M240237", name: "M240237", showName: "404" },
      { deviceId: "M240270", name: "M240270", showName: "405" },
      { deviceId: "M240337", name: "M240337", showName: "406" },
      { deviceId: "M240200", name: "M240200", showName: "407" },
      { deviceId: "M240332", name: "M240332", showName: "408" },
      { deviceId: "M240331", name: "M240331", showName: "409" },
      { deviceId: "M240341", name: "M240341", showName: "410" },
      { deviceId: "M240280", name: "M240280", showName: "411" },
      { deviceId: "M240312", name: "M240312", showName: "412" },
      { deviceId: "M240292", name: "M240292", showName: "413" },
      { deviceId: "M240278", name: "Room no 414", showName: "414" },
      { deviceId: "M240283", name: "M240283", showName: "415" },
      { deviceId: "M240229", name: "M240229", showName: "501" },
      { deviceId: "M240238", name: "M240238", showName: "502" },
    ],
  },
  {
    userName: "le sutra",
    password: "password",
    deviceId: [
      { deviceId: "M280098", name: "M280098", showName: "Reception" },
      { deviceId: "M280108", name: "M280108", showName: "Reception" },
      {
        deviceId: "M280169",
        name: "M280169",
        showName: "3rd floor corridor ",
      },
      { deviceId: "M280119", name: "M280119", showName: "GM office " },
      {
        deviceId: "M280250",
        name: "M280250",
        showName: "Second floor corridor",
      },
      { deviceId: "M280221", name: "M280221", showName: "IT Room " },
      { deviceId: "M280229", name: "M280229", showName: "109 Spa" },
      { deviceId: "M280203", name: "M280203", showName: "106" },
      { deviceId: "M280192", name: "M280192", showName: "107" },
      { deviceId: "M280199", name: "M280199", showName: "108" },
      { deviceId: "M280216", name: "M280216", showName: "201" },
      { deviceId: "M280150", name: "M280150", showName: "202" },
      { deviceId: "M280188", name: "M280188", showName: "203" },
      { deviceId: "M280173", name: "M280173", showName: "204" },
      { deviceId: "M280127", name: "M280127", showName: "205" },
      { deviceId: "M280198", name: "M280198", showName: "206" },
      { deviceId: "M280287", name: "M280287", showName: "207" },
      { deviceId: "M280262", name: "M280262", showName: "208" },
      { deviceId: "M280231", name: "M280231", showName: "209" },
      { deviceId: "M280107", name: "M280107", showName: "306" },
      { deviceId: "M280096", name: "M280096", showName: "307" },
      { deviceId: "M280264", name: "M280264", showName: "308" },
      { deviceId: "M280175", name: "M280175", showName: "309" },
    ],
  },
  {
    userName: "looksSaloon",
    password: "password",
    deviceId: [
      { deviceId: "M240225", name: "Pedi sec 5", showName: "Pedi sec 5" },
      { deviceId: "M250040", name: "Ladies sec3", showName: "Ladies sec3" },
      { deviceId: "M250038", name: "Jens sec 4", showName: "Jens sec 4" },
      { deviceId: "M240190", name: "Room no 8", showName: "Room no 8" },
      { deviceId: "M240320", name: "Staf room 1", showName: "Staf room 1" },
      { deviceId: "M240321", name: "Room 9", showName: "Room 9" },
      {
        deviceId: "M240311",
        name: "Front desk 2",
        showName: "Front desk 2",
      },
      { deviceId: "M240319", name: "Room no 7", showName: "Room no 7" },
      { deviceId: "M240316", name: "Makeup room", showName: "Makeup room" },
    ],
  },
  {
    userName: "subway",
    password: "password",
    deviceId: [
      {
        deviceId: "M280167",
        name: "Counter temp",
        showName: "Counter temp",
      },
      { deviceId: "M250045", name: "M250045", showName: "M250045" },
      { deviceId: "M250053", name: "Tostur ", showName: "Tostur " },
      { deviceId: "M280181", name: "Griller ", showName: "Griller " },
      { deviceId: "M240273", name: "Dining area", showName: "Dining area" },
      { deviceId: "M240230", name: "Counter AC", showName: "Counter AC" },
      {
        deviceId: "M280109",
        name: "Sitting area temp",
        showName: "Sitting area temp",
      },
    ],
  },
  {
    userName: "TATA_AIG",
    password: "password",
    deviceId:[
      { deviceId: 'M240257', name: '01', showName: '01' },
      { deviceId: 'M240291', name: '02', showName: '02' },
      { deviceId: 'M240259', name: '03', showName: '03' },
      { deviceId: 'M240256', name: '04', showName: '04' },
      { deviceId: 'M240271', name: '05', showName: '05' },
      { deviceId: 'M240272', name: '06', showName: '06' },
      { deviceId: 'M240297', name: '07', showName: '07' }
    ]
  }
];

export default function Login() {
  const [validated, setValidated] = useState(false);
  const navigate = useNavigate();
  let dispatch = useDispatch();
  useEffect(()=>{
    console.log(document.URL);
    const faviconUpdate = async () => {
     //grab favicon element by ID
     const favicon = document.getElementById("favicon");

     //check count value, if below 0 we change href property to our red circle image path
     if (document.URL.includes("https://energy.smarden.tech")) {
        favicon.href = `/smartDen.ico`;
        document.title="Energy Savings"
     }
     //if above 0, we set back to green
     else {
       favicon.href = `/favicon.ico`;
     }
   };
   //run our function here
   faviconUpdate();
   },[])
  const handleSubmit = (event) => {
    event.preventDefault();
    const form = new FormData(event.currentTarget);

    let data = {
      userName: form.get("loginId"),
      password: form.get("password"),
    };
    console.log(data,"data");
    const userFound = user.filter((u) => {
      if (u.userName === data.userName) {
        return u;
      }
    })
    if (userFound.length === 0) {
      messages.error("Login Details Not Match");
      return;
    }
    
    if (data.userName !== userFound[0].userName) {
      messages.error("Login Details Not Match");
      return;
    }
    if (data.password !== userFound[0].password) {
      messages.error("Login Details Not Match");
      return;
    }
    dispatch(loginDetail(userFound[0]));
    if (userFound[0].deviceId[0].phase) {
      navigate(`/dashboard/phase/${userFound[0].deviceId[0].deviceId}`);
    } else {
      navigate(`/dashboard/data/${userFound[0].deviceId[0].deviceId}`);
    }
  };
  useEffect(() => {
    loadData();
  }, []);
  return (
    <div style={{ display: "flex", height: "100vh", alignItems: "center" }}>
      <div
        style={{
          backgroundColor: "#F6F8FD",
          width: "52vw",
          height: "100vh",
          padding: "1vw 3.5vh",
        }}
      >
        <div style={{ display: "flex" }}>
          <img
            src={document.URL.includes("https://energy.smarden.tech")?images.smartDen:images.loginLogo}
            style={{
              width: "10vw",
            }}
          />
        </div>
        <div>
          <img
            src={images.centerBanner}
            style={{
              width: "35vw",
              marginTop: "5vh",
            }}
          />
          <div
            style={{
              marginTop: "2vh",
              fontSize: "1.4rem",
              fontFamily: "manrope",
            }}
          >
            Saving <strong>energy</strong> and <strong>increasing life</strong>{" "}
            of your appliances and machines
          </div>
        </div>
        {
         !document.URL.includes("https://energy.smarden.tech")&&(
          <div
          style={{
            position: "absolute",
            bottom: "2.5vh",
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            alignItems: "flex-start",
            width: "45vw",
          }}
        >
          <div
            style={{
              color: "#00000050",
              borderBottom: "1px solid #00000050",
              width: "100%",
              textAlign: "left",
              paddingBottom: "1rem",
              fontFamily: "manrope",
            }}
          >
            Featured On
          </div>
          <div>
            <img
              src={images.bottomBanner}
              style={{
                width: "45vw",
                marginTop: "10px",
              }}
            />
          </div>
        </div>
         )
        }
       
      </div>
      <div
        style={{
          width: "48vw",
          height: "100vh",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
        }}
      >
        <div
          style={{
            width: "25vw",
            textAlign: "left",
            borderBottom: "1px solid #CECECE",
            paddingBottom: "1rem",
          }}
        >
          <div
            style={{
              fontSize: "2rem",
              fontFamily: "manrope",
              fontWeight: "800",
            }}
          >
            WELCOME BACK
          </div>
          <div
            style={{
              color: "#999999",
              fontFamily: "manrope",
              fontSize: "0.8rem",
            }}
          >
            Enter username and password to login!
          </div>
        </div>
        <form onSubmit={handleSubmit} style={{ width: "25vw" }}>
          <Form.Group as={Col} style={{ marginTop: "1.5rem" }}>
            <Form.Control
              type="text"
              placeholder="Username"
              required
              name="loginId"
              autoComplete="loginId"
              style={{
                height: "45px",
              }}
            />
          </Form.Group>
          <Form.Group as={Col} style={{ marginTop: "1.5rem" }}>
            <Form.Control
              type="password"
              placeholder="Password"
              required
              name="password"
              autoComplete="current-password"
              style={{
                height: "45px",
              }}
            />
          </Form.Group>
          <Button
            type="submit"
            style={{ marginTop: "1.5rem", width: "100%", height: "45px" }}
          >
            login
          </Button>
        </form>
      </div>
      <Toaster />
    </div>
  );
}
