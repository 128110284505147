import axios from "axios"
import { collectiveData } from "../actionTypes"
import { serverUrl } from "../../../ApiRoute"
import store from ".."



export const loadMultipleData = (data)=>{
    return {
        type : collectiveData.LOAD,
        payload :  data
    }
}

export const loadData = async(deviceId)=>{
   return await axios.post(`${serverUrl.aws}/analysis/today`,{
        "deviceId": deviceId,
    }).then(function (res) {
        if(res.data.success){
            return {...res.data.data.snapshot,deviceId:deviceId}
        }else{
            return {}
        }
    }).catch(function (err) {
        return {}
    })
}

export const loadMulipleApi = async(deviceIds) =>{
  
   let combineData = []
    for(let d of deviceIds){
       await loadData(d.deviceId).then((res)=>combineData.push(res)).catch(err=>console.log(err))
    }
 
   store.dispatch(loadMultipleData(combineData))

}

export const loadDataDay = async(deviceId,date)=>{    
   return await axios.post(`${serverUrl.aws}/analysis/day`,{
        "deviceId": deviceId,
        "day": date
    }).then(function (res) {
        if(res.data.success){
           return {...res.data.data.snapshot,deviceId:deviceId}
        }else{
            return {}
        }
    }).catch(function (err) {
            return {}
    })
}

export const loadMulipleDateApi = async(deviceIds,date) =>{
  
    let combineData = []
     for(let d of deviceIds){
        await loadDataDay(d.deviceId,date).then((res)=>combineData.push(res)).catch(err=>console.log(err))
     }
  
    store.dispatch(loadMultipleData(combineData))
 
 }

 export const loadDataofDiviceWithStartEndTime = async(deviceId,startTime,endTime)=>{
    
   return await axios.post(`${serverUrl.aws}/analysis/device`,{
        "deviceId": deviceId,
        "startTime": startTime,
        "endTime":endTime
    }).then(function (res) {
        if(res.data.success){
           return {...res.data.data.snapshot,deviceId:deviceId}
        }else{
           return {}
        }
    }).catch(function (err) {
        return {}
    })
}

export const loadMulipleWithStartEndTimeApi = async(deviceIds,startTime,endTime) =>{
  
    let combineData = []
     for(let d of deviceIds){
        await loadDataofDiviceWithStartEndTime(d.deviceId,startTime,endTime).then((res)=>combineData.push(res)).catch(err=>console.log(err))
     }
  
    store.dispatch(loadMultipleData(combineData))
 
 }