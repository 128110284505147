export const moneySavedChart =(data)=>{
    console.log(data,"valval");
    let val = 0
    console.log(val,"val");
    for(let i of data){
        if(Object.keys(i).length!==0){
        val+=i.totalSaved
        }
    }
    return `${val.toFixed(2)} Rs`   
}

export const totalCarbonConsume =(data)=>{
    let val = 0

    for(let i of data){
        if(i!==undefined && Object.keys(i).length!==0){
        val+=i.totalSaved!==undefined?i.totalSaved:0
        }
    }
    return isNaN(val)?0:val
}

export const onlineOffline = (data) =>{
    let totaldevice = data.length
    let online = 0
    for(let i of data){
        if(Object.keys(i).length===0) continue
        if(i.connected){
          online +=1
        }
    }
   return `${online}/${totaldevice}`
}

export const getRandomIntegerInclusive = (min, max) => {
    min = Math.ceil(min)
    max = Math.floor(max)
  
    return Math.floor(Math.random() * (max - min + 1)) + min
  }