import React, { useEffect, useState } from 'react'
import ReactApexChart from "react-apexcharts";

function MoneySavedBar({barDatas}) {
  console.log(barDatas,"money bar");
  const [series,setSeries] = useState([])
  const [options,setOptions] = useState({
    colors:['#1AC821'],
    chart:{
      stacked:true
    },
    plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: '55%',
          endingShape: 'rounded',

          borderRadius: 5.556,
          borderRadiusApplication: 'end',
        },
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        show: true,
        width: 2,
        colors: ['transparent']
      },
      xaxis: {
        categories: barDatas.categories!==undefined?barDatas.categories:[],
      },
      yaxis: {
        title: {
          text: 'RS'
        },
        labels:{
        formatter:function (val) {
            return  `${(val)} `
          },
        },
      },
      fill: {
        opacity: 1
      },
      tooltip: {
        y: {
          formatter: function (val) {
            return  `${(val)} Rs`
          }
        }
      }
      
  })
  useEffect(()=>{
    if(barDatas.length===0) return
    let seriesData = []
    for(let i=0 ; i<barDatas.length ; i++){
    
      let obj = {name:`Data ${i}`,data:Object.keys(barDatas[i]).length<=1?[]:barDatas[i].data}
      seriesData.push(obj)
    }
    console.log(seriesData,"monry bar");
    let categories = []
    for(let i of barDatas){
      if(Object.keys(i).length<=1) continue
      if(i.categories.length!==0){
        categories=i.categories
      }
    }
  
    setSeries(seriesData)
    setOptions({
       colors:['#1AC821'],
      chart:{
        stacked:true
      },
      plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: '55%',
            endingShape: 'rounded',
  
            borderRadius: 5.556,
            borderRadiusApplication: 'end',
          },
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          show: true,
          width: 2,
          colors: ['transparent']
        },
        xaxis: {
          categories: categories,
        },
        yaxis: {
          title: {
            text: 'Rupees'
          },
          labels:{
          formatter:function (val) {
              return  `${(val).toFixed(2)} `
            },
          },
        },
        fill: {
          opacity: 1
        },
        tooltip: {
          y: {
            formatter: function (val) {
              return  `${(val).toFixed(2)} Rs`
            }
          }
        }
        
    })
  },[barDatas])
  return (
    <div>
        <ReactApexChart
          series={series}
          options={options}
          type={'bar'}
          height={215}
          stacked={true}
        />
    </div>
  )
}

export default MoneySavedBar