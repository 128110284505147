export const toadyData ={
    LOAD:'LOAD',
    REMOVE_LOAD:'REMOVE_LOAD',
    LOADTWO:'LOADTWO',
    REMOVE_LOADTWO:'REMOVE_LOADTWO'
}
export const loginDetails = {
    LOGINUSER:'LOGINUSER'
}

export const collectiveData = {
    LOAD:'MULTIPLE_LOAD'
}