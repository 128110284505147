import React, { useEffect, useState } from 'react'
import SideBar from '../SideBar/SideBar'
import Header from '../Header/Header'
import Main from './Main'
import { loadData, loadDataofDivice2 } from '../redux/action/TodatData'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import MainV2 from './MainV2'
import { loadMulipleApi } from '../redux/action/totalData'
import Loading from '../common/Loading'
import Detail from './Detail'

function MainPage() {
  const login = useSelector(state=>state.login)
  const [loading,setLoading] = useState(false)
  const [dateA, setDateA] = useState(new Date());

  let { id ,type} = useParams();
    const styles = {
        container:{
            height:'100vh',
            width:'100vw',
            display:'flex',
        },
        rightContainer:{
            height:'100vh',
            width:'94vw',
            display:'flex',
            flexDirection: 'column',
        }
    }
    useEffect(()=>{
      if(Object.keys(login).length===0) return
      if(id!=="total"){
        setLoading(true)
      loadMulipleApi([{deviceId:id}])
      }else{
        let deviceId = login.deviceId.filter((id)=>{
          if(id.metered===undefined){
            return id
          }
        })
        setLoading(true)
        loadMulipleApi(deviceId)
      }
      if(id==="M240002"){
        setLoading(true)
        loadDataofDivice2("M250001")
      }
    },[login])
  return (
    <div style={{...styles.container}}>
        <SideBar setLoading={setLoading} dateA={dateA} setDateA={setDateA}/>
        <div style={{...styles.rightContainer}}>
          <Header/>
          {
            login.link===undefined && type==="data"?<MainV2 setLoading={setLoading} dateA={dateA} setDateA={setDateA}/>:<Detail  setLoading={setLoading} dateA={dateA} setDateA={setDateA}/>

          }
        </div>
        {loading && <Loading />}
    </div>
  )
}

export default MainPage