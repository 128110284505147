import './App.css';
import Login from './Login/Login';
import MainPage from './component/MainPage/MainPage';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css'
import {createBrowserRouter,RouterProvider} from 'react-router-dom'

const router = createBrowserRouter([
  {
    path: "/",
    element: <Login />,
  },
  {
    path: "/dashboard/:type/:id",
    element: <MainPage />,
  },
  {
    path: "*",
    element: <Login />,
  },
])
function App() {
  return (
    <div className="App">
     <RouterProvider router={router}/>    
    </div>
  );
}

export default App;
