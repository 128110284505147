import axios from "axios"
import { toadyData } from "../actionTypes"
import { serverUrl } from "../../../ApiRoute"
import store from ".."
import moment from "moment"

export const todayDataLoad = (data)=>{
    console.log(data,"action");
    return {
        type:toadyData.LOAD,
        payload:data
    }
}

export const removeTodayLoadedData = ()=>{
    return {
        type:toadyData.REMOVE_LOAD,
        payload:{}
    }
}

export const todayData0f2ndLoad = (data)=>{
    console.log(data,"action");
    return {
        type:toadyData.LOADTWO,
        payload:data
    }
}

export const removeTodayLoadedDataof2ndData = ()=>{
    return {
        type:toadyData.REMOVE_LOADTWO,
        payload:{}
    }
}

export const loadData = async(deviceId)=>{
    store.dispatch(removeTodayLoadedData())
    let date = new Date()
    // date.setDate(date.getDate()-1)
    await axios.post(`${serverUrl.aws}/analysis/today`,{
        "deviceId": deviceId,
        "day": date
    }).then(function (res) {
        if(res.data.success){
            store.dispatch(todayDataLoad(res.data.data.snapshot))
            console.log('====================================');
            console.log(res.data.data.snapshot,"today");
            console.log('====================================');
        }else{
            store.dispatch(todayDataLoad({}))
        }
    }).catch(function (err) {
        store.dispatch(todayDataLoad({}))
    })
}

export const loadDataDay = async(deviceId,date)=>{
    store.dispatch(removeTodayLoadedData())
    
    // date = new Date(date)
    // date.setDate(date.getDate()+1)
    await axios.post(`${serverUrl.aws}/analysis/day`,{
        "deviceId": deviceId,
        "day": date
    }).then(function (res) {
        if(res.data.success){
            store.dispatch(todayDataLoad(res.data.data.snapshot))
            console.log(res.data.data.snapshot,"day");
        }else{
            store.dispatch(todayDataLoad({}))
        }
    }).catch(function (err) {
        store.dispatch(todayDataLoad({}))
    })
}

export const loadDataofDivice2 = async(deviceId)=>{
    store.dispatch(removeTodayLoadedDataof2ndData())
    let date = new Date()
    // date.setDate(date.getDate()-1)
    await axios.post(`${serverUrl.aws}/analysis/today`,{
        "deviceId": deviceId,
        "day": date
    }).then(function (res) {
        if(res.data.success){
            store.dispatch(todayData0f2ndLoad(res.data.data.snapshot))
            console.log('====================================');
            console.log(res.data.data.snapshot,"today");
            console.log('====================================');
        }else{
            store.dispatch(todayData0f2ndLoad({}))
        }
    }).catch(function (err) {
        store.dispatch(todayData0f2ndLoad({}))
    })
}

export const loadDataofDivice2Day = async(deviceId,date)=>{
    store.dispatch(removeTodayLoadedDataof2ndData())  
    // date = new Date(date)
    // date.setDate(date.getDate()+1)
    await axios.post(`${serverUrl.aws}/analysis/day`,{
        "deviceId": deviceId,
        "day": date
    }).then(function (res) {
        if(res.data.success){
            store.dispatch(todayData0f2ndLoad(res.data.data.snapshot))
            console.log(res.data.data.snapshot,"day");
        }else{
            store.dispatch(todayData0f2ndLoad({}))
        }
    }).catch(function (err) {
        store.dispatch(todayData0f2ndLoad({}))
    })
}

export const loadDataofDiviceWithStartEndTime = async(deviceId,startTime,endTime)=>{
    // store.dispatch(removeTodayLoadedDataof2ndData())  
    // date = new Date(date)
    // date.setDate(date.getDate()+1)
    store.dispatch(removeTodayLoadedData())
    await axios.post(`${serverUrl.aws}/analysis/device`,{
        "deviceId": deviceId,
        "startTime": startTime,
        "endTime":endTime
    }).then(function (res) {
        console.log('====================================');
        console.log(res);
        console.log('====================================');
        if(res.data.success){
            store.dispatch(todayDataLoad(res.data.data.snapshot))
            console.log(res.data.data.snapshot,"day");
        }else{
            store.dispatch(todayData0f2ndLoad({}))
        }
    }).catch(function (err) {
        store.dispatch(todayData0f2ndLoad({}))
    })
}