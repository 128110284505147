import React, { useEffect, useState } from "react";
import DetailContainer from "./common/DetailContainer";
import { useSelector } from "react-redux";
import LineChart from "../Chart/LineChart";
import ChartHeader from "../Chart/ChartHeader";
import PieChart from "../Chart/PieChart";
import BarChart from "../Chart/BarChart";
import SingleValuePieChart from "../Chart/SingleValuePieChart";
import TextContainer from "./common/TextContainer";
import { images } from "../common/images";
import { user } from "../../Login/Login";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import {
  loadData,
  loadDataDay,
  loadDataofDivice2,
  loadDataofDivice2Day,
  loadDataofDiviceWithStartEndTime,
} from "../redux/action/TodatData";
import { useParams } from "react-router-dom";
import { Col, Form, Row } from "react-bootstrap";
import { Grid, TextField, Button } from "@mui/material";
import {
  averageP,
  averagePower,
  carbonConsumed,
  carbonFootprintSaved,
  moneySavedSmartOffSessions,
  powerCompunstion,
  temperatureData,
} from "../common/function";
import CarbonConsumeBarChart from "../Chart/CarbonConsumeBarChart";
import MoneySavedBar from "../Chart/MoneySavedBar";
import {
  moneySavedChart,
  onlineOffline,
  totalCarbonConsume,
} from "../common/util";
import {
  loadMulipleApi,
  loadMulipleDateApi,
  loadMulipleWithStartEndTimeApi,
} from "../redux/action/totalData";
import moment from "moment";
import { messages } from "../common/notification";
import {
  autoTimerStop,
  autoTimersStart,
  deviceControl,
  deviceDetails,
} from "../common/ApiCall";
import { Toaster } from "react-hot-toast";
import { Cursor } from "react-bootstrap-icons";
import IntervlLineChart from "../Chart/IntervlLineChart";

const styles = {
  container: {
    background: "#F5F7FA",
    height: "90vh",
    width: "100%",
    paddingTop: "2%",
    paddingRight: "2%",
    paddingLeft: "2%",
    overflowY: "scroll",
  },
  smallBoxContainer: {
    marginTop: "20px",
  },
  chartContainer: {
    width: "100%",
    height: "317px",
    background: "#FFFFFF",
    borderRadius: "14px",
    marginTop: "2%",
    padding: "24px",
  },
  smallPieContainer: {
    display: "flex",
    alignItems: "center",
    gap: "10px",
  },
};
function Detail({ setLoading, dateA, setDateA }) {
  let { id } = useParams();
  // let toadyData = useSelector((state) => state.todayData);
  let toadyData2 = useSelector((state) => state.todayData2);
  let mulipleData = useSelector((state) => state.mulipleData);
  const login = useSelector((state) => state.login);
  const [deviceDetail, setDeviceDetails] = useState({});
  const [value, setValue] = useState(null);

  const [deviceId, setDeviceId] = useState(id);
  const [details, setDetails] = useState({
    startTime: "",
    endTime: "",
    deviceId: id,
  });

  // new State
  const [globalSavingData, setGlobalSavingData] = useState({
    globalMoneySaved: 0,
    globalMoneySpent: 0,
    globalUnitSaved: 0,
    globalUnitConsumed: 0,
    globalUnitSavingPercentge: 0,
  });
  const [globalMoneySavedChartData, setGlobalMoneySavedChartData] = useState(
    []
  );
  const [globalCarbonConsumeChartData, setGlobalCarbonConsumeChartData] =
    useState([]);
  const [globalCarbonSavedChartData, setGlobalCarbonSavedChartData] = useState(
    []
  );
  const [globalTemperatureChartData, setGlobalTemperatureChartData] = useState(
    []
  );
  const [globalPowerConsumtionChartData, setGlobalPowerConsumtionChartData] =
    useState([]);
  const [globalUnitConsumedLine, setGlobalUnitConsumedLine] = useState({});

  const [loadLogsPhase, setLoadLogsPhase] = useState({});
  const [unitConsumePhase, setUnitConsumePhase] = useState({});
  const threeHour = ["habitat"];
  const portelStatus = ["habitat"];
  const OnlineStatus = ["habitat"];
  const chartNotIncluse = ["M280014", "M280011", "M280010", "M280013"];
  const chartShow = [
    "yourspace",
    "wiley",
    "campus",
    "campus2",
    "oceans",
    "vietnom",
    "paras",
    "bata",
    "steelo",
    "grandcentral",
    "sarthakpalace",
    "alankaram",
    "ARS",
    "rajiv",
    "bikanerwala",
    "aliste",
    "demo",
    "stanza",
    "chetanya",
    "rishankDabra",
  ];
  const chartNotShow = [
    "yourspace",
    "wiley",
    "campus",
    "campus2",
    "oceans",
    "vietnom",
    "paras",
    "bata",
    "steelo",
    "sarthakpalace",
    "ARS",
    "alankaram",
    "rajiv",
    "laffaire",
    "bikanerwala",
    "aliste",
    "stanza",
    "chetanya",
    "rishankDabra",
  ];
  const autoCutShow = ["aliste", "laffaire", "steelo", "rajiv"];
  useEffect(() => {
    if (id !== "total") {
      deviceDetails(id)
        .then((res) => {
          setDeviceDetails(res);
        })
        .catch((err) => {
          setDeviceDetails({});
        });
    }
  }, [mulipleData]);
  useEffect(() => {
    if (mulipleData.length === 0) return;
    setLoading(true);
    let cpu = login.cpu !== undefined ? login.cpu : 15;
    let globalCarbonConsumedData = [];
    let globalCarbonSavedData = [];
    let globalMoneySavedData = [];
    let globalTemperatureLogData = [];
    let globalPowerConsumtion = [];

    let globalMoneySpent = 0;
    let globalMoneySaved = 0;
    let globalUnitConsumed = 0;
    let globalUnitSaved = 0;
    let globalUnitSavingPercentge = 0;
    let unitConsumeLineChart = [];
    for (let data of mulipleData) {
      if (Object.keys(data).length === 0) continue;
      let loadLogsSort = data.loadLogs.sort(
        (a, b) => parseFloat(a.ts) - parseFloat(b.ts)
      );
      let shortAccordingM = {
        m0: [],
      };
      for (let p of loadLogsSort) {
        if (p.m === undefined || p.m === 0) {
          shortAccordingM.m0.push(p);
        } else {
          if (shortAccordingM[`m${p.m}`] === undefined) {
            shortAccordingM[`m${p.m}`] = [];
          }
          shortAccordingM[`m${p.m}`].push(p);
        }
      }
      setLoadLogsPhase(shortAccordingM);

     
      let unitPhase = { total: 0 };
      for (let m of Object.keys(shortAccordingM)) {
        let carbonConsumedData = carbonConsumed(
          shortAccordingM[m],
          data.deviceId
        );
     
        unitPhase = {
          ...unitPhase,
          [m]: carbonConsumedData.unitConsumed,
          total: unitPhase.total + carbonConsumedData.unitConsumed,
        };
      }
      setUnitConsumePhase(unitPhase);

      // Average Power
      let averageP =
        login.userName === user[1].userName
          ? 2200
          : averagePower(data.loadLogs);

      // carbon Consume
      // let carbonConsumedData = carbonConsumed(loadLogsSort, data.deviceId);
      // globalCarbonConsumedData.push(carbonConsumedData)
    }

    setLoading(false);
  }, [mulipleData]);

  const today = new Date();
  // Set yesterday's date
  const yesterday = new Date(today);
  yesterday.setDate(yesterday.getDate() - 1);

  const collectiveLog = (data, key) => {
    let total = 0;
    for (let m of Object.keys(data)) {
      let pp = data[m];
      console.log(pp, "pp");
      total += pp[pp.length - 1][key];
    }
    return total;
  };
  const collectiveLogTime = (data) => {
    let total = 0;
    for (let m of Object.keys(data)) {
      let pp = data[m];
      console.log(pp, "pp");
      total = pp[pp.length - 1].ts;
    }
    return total;
  };
  return (
    <div style={{ ...styles.container }}>
      <Toaster />
      <Grid container spacing={1}>
        <Grid item xs={8} md={3}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DemoContainer components={["DatePicker"]}>
              <DatePicker
                disableFuture
                disableHighlightToday
                value={value}
                onChange={(newValue) => {
                  setLoading(true);
                  let d = new Date(newValue);
                  if (login.userName !== "aliste") {
                    if (
                      new Date(d).setHours(0, 0, 0, 0) !==
                      new Date().setHours(0, 0, 0, 0)
                    ) {
                      if (id === "total") {
                        setDateA(newValue.$d);
                        loadMulipleDateApi(login.deviceId, newValue.$d);
                      } else {
                        setDateA(newValue.$d);
                        loadMulipleDateApi([{ deviceId: id }], newValue.$d);
                      }
                      if (id === "M240002") {
                        setDateA(newValue.$d);
                        loadDataofDivice2Day("M250001", newValue.$d);
                      }
                    } else {
                      if (id === "total") {
                        setDateA(newValue.$d);
                        loadMulipleApi(login.deviceId);
                      } else {
                        setDateA(newValue.$d);
                        loadMulipleApi([{ deviceId: id }]);
                      }
                      if (id === "M240002") {
                        setDateA(newValue.$d);
                        loadDataofDivice2("M250001");
                      }
                    }
                  } else {
                    setDateA(newValue.$d);
                  }
                }}
              />
            </DemoContainer>
          </LocalizationProvider>
        </Grid>
        {login.userName === "aliste" && (
          <>
            <Grid item xs={8} md={2} sx={{ marginTop: "8px" }}>
              <TextField
                id="outlined-basic"
                label="Device id"
                variant="outlined"
                onChange={(e) => setDeviceId(e.target.value)}
                value={deviceId}
              />
            </Grid>
            <Grid item xs={8} md={2} sx={{ marginTop: "8px" }}>
              <Button
                variant="contained"
                size="large"
                onClick={() => {
                  if (
                    new Date(dateA).setHours(0, 0, 0, 0) !==
                    new Date().setHours(0, 0, 0, 0)
                  ) {
                    loadMulipleDateApi([{ deviceId: deviceId }], dateA);
                    if (id === "M240002") {
                      loadDataofDivice2Day("M250001", dateA);
                    }
                  } else {
                    loadMulipleApi([{ deviceId: deviceId }]);
                    if (id === "M240002") {
                      loadDataofDivice2("M250001");
                    }
                  }
                }}
              >
                Get Details
              </Button>
            </Grid>
          </>
        )}
      </Grid>
      {login.userName === "aliste" && (
        <Row className="mt-5 ">
          <Col md={3} style={{ textAlign: "left" }}>
            <Form.Label htmlFor="inputPassword5">Start Date</Form.Label>
            <Form.Control
              type="datetime-local"
              id="inputPassword5"
              aria-describedby="passwordHelpBlock"
              value={details.startTime}
              onChange={(e) =>
                setDetails({ ...details, startTime: e.target.value })
              }
            />
          </Col>
          <Col md={3} style={{ textAlign: "left" }}>
            <Form.Label htmlFor="inputPassword5">End Date</Form.Label>
            <Form.Control
              type="datetime-local"
              id="inputPassword5"
              aria-describedby="passwordHelpBlock"
              value={details.endTime}
              onChange={(e) =>
                setDetails({ ...details, endTime: e.target.value })
              }
            />
          </Col>
          <Col md={3} style={{ textAlign: "left" }}>
            <Form.Label htmlFor="inputPassword5">Device Id</Form.Label>
            <Form.Control
              type="text"
              id="inputPassword5"
              aria-describedby="passwordHelpBlock"
              value={details.deviceId}
              onChange={(e) =>
                setDetails({ ...details, deviceId: e.target.value })
              }
            />
          </Col>
          <Col md={3} style={{ textAlign: "left" }}>
            <Button
              className="mt-4"
              variant="contained"
              onClick={() =>
                loadMulipleWithStartEndTimeApi(
                  [{ deviceId: details.deviceId }],
                  details.startTime,
                  details.endTime
                )
              }
            >
              Get Data
            </Button>
          </Col>
        </Row>
      )}

      {Object.keys(loadLogsPhase).length > 0 && id !== "total" && (
        <>
          <h3
            style={{ textAlign: "left", marginLeft: "15px", marginTop: "15px" }}
          >
            Total
          </h3>
          <Row style={{ ...styles.smallBoxContainer }} className="row g-4 ">
            <DetailContainer
              name={"Voltage"}
              value={`${(
                collectiveLog(loadLogsPhase, "v") /
                Object.keys(loadLogsPhase).length
              ).toFixed(2)} V`}
              time={collectiveLogTime(loadLogsPhase)}
              color={"#FDECEC"}
              borderColor={"#EF4444"}
              image={images.voltage}
            />
            <DetailContainer
              name={"Power"}
              value={`${collectiveLog(loadLogsPhase, "p").toFixed(2)} W`}
              time={collectiveLogTime(loadLogsPhase)}
              color={"#FDECEC"}
              borderColor={"#EF4444"}
              image={images.powerFector}
            />
            <DetailContainer
              name={"Current"}
              value={`${collectiveLog(loadLogsPhase, "c").toFixed(2)} A`}
              time={collectiveLogTime(loadLogsPhase)}
              color={"#FDECEC"}
              borderColor={"#EF4444"}
              image={images.voltage}
            />
            {Object.keys(unitConsumePhase).length > 0 &&
              unitConsumePhase.total !== undefined && (
                <DetailContainer
                  name={"Unit Consume"}
                  value={`${unitConsumePhase.total.toFixed(2)} kWh`}
                  time={collectiveLogTime(loadLogsPhase)}
                  color={"#FDECEC"}
                  borderColor={"#EF4444"}
                  image={images.unitConsumed}
                />
              )}
            <DetailContainer
              name={"Power Factor"}
              value={`${(
                collectiveLog(loadLogsPhase, "pf") /
                Object.keys(loadLogsPhase).length
              ).toFixed(2)}`}
              time={collectiveLogTime(loadLogsPhase)}
              color={"#FDECEC"}
              borderColor={"#EF4444"}
              image={images.powerFector}
            />
          </Row>
        </>
      )}

      {Object.keys(loadLogsPhase).length > 1 &&
        id !== "total" &&
        Object.keys(loadLogsPhase).map((k, i) => {
          let da = loadLogsPhase[k];
          return (
            <>
              <h3
                style={{
                  textAlign: "left",
                  marginLeft: "15px",
                  marginTop: "15px",
                }}
              >
                Phase {i + 1}
              </h3>
              <Row
                style={{ ...styles.smallBoxContainer }}
                className="row g-4 mt-0"
              >
                <DetailContainer
                  name={"Voltage"}
                  value={`${da[da.length - 1].v.toFixed(2)} V`}
                  time={da[da.length - 1].ts}
                  color={"#FDECEC"}
                  borderColor={"#EF4444"}
                  image={images.voltage}
                />
                <DetailContainer
                  name={"Power"}
                  value={`${da[da.length - 1].p.toFixed(2)} W`}
                  time={da[da.length - 1].ts}
                  color={"#FDECEC"}
                  borderColor={"#EF4444"}
                  image={images.powerFector}
                />
                <DetailContainer
                  name={"Current"}
                  value={`${da[da.length - 1].c.toFixed(2)} A`}
                  time={da[da.length - 1].ts}
                  color={"#FDECEC"}
                  borderColor={"#EF4444"}
                  image={images.voltage}
                />
                {Object.keys(unitConsumePhase).length > 0 &&
                  unitConsumePhase[k] !== undefined && (
                    <DetailContainer
                      name={"Unit Consume"}
                      value={`${unitConsumePhase[k].toFixed(2)} kWh`}
                      time={da[da.length - 1].ts}
                      color={"#FDECEC"}
                      borderColor={"#EF4444"}
                      image={images.unitConsumed}
                    />
                  )}
                <DetailContainer
                  name={"Power Factor"}
                  value={da[da.length - 1].pf}
                  time={da[da.length - 1].ts}
                  color={"#FDECEC"}
                  borderColor={"#EF4444"}
                  image={images.powerFector}
                />
              </Row>
            </>
          );
        })}
      <div className="mb-5"></div>

      {login.graph && Object.keys(loadLogsPhase).length > 0 && id !== "total" && (
        <Row>
          {Object.keys(loadLogsPhase).map((k, i) => {
            let da = loadLogsPhase[k];
            console.log(da,"ffffff");
            // Power Consmption
            let power = powerCompunstion(da);
             console.log(power,"power");
            return  <Col md={6} sm={12}>
            <div style={{ ...styles.chartContainer }}>
              <ChartHeader
                name={`Power Consumption`}
                subName={`${da.length>0? da[da.length-1].p:0} W`}
                image={images.powerCompunsion}
                textColor={"#322FC8"}
                iconBackgroundColor={"rgba(50, 47, 200, 0.10)"}
              />
              <LineChart seriesData={[{data:power,name:`${id} Phase ${k}`}]} degree={"Power"} />
            </div>
          </Col>;
          })}
        </Row>
      )}
    </div>
  );
}

export default Detail;
