import React from "react";
import logo from "../../assets/logo.png";
import DasbordIcon from "../Svg/DasbordIcon";
import {
  loadData,
  loadDataofDivice2,
  loadDataofDivice2Day,
} from "../redux/action/TodatData";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import "./SideBar.css";
import { Tooltip } from "react-tooltip";
import { loadMulipleApi, loadMulipleDateApi } from "../redux/action/totalData";

function SideBar({ setLoading, dateA, setDateA }) {
  const login = useSelector((state) => state.login);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();

  const styles = {
    // sideBarContainer: {
    //   width: '6vw',
    //   height: '100vh',
    //   backgroundColor: '#FFFFFF'
    // },
    active: {
      backgroundColor: "#2050ff20",
      // width: "max-content",
      padding: "11px",
      borderRadius: "8px",
    },
  };
  const styleAll = {
    width: "40.304px",
    height: "36px",
  };
  return (
    <div className="sideBarContainer">
      <div style={{ marginTop: "20px" }}>
        <img
          src={login.logo !== undefined ? login.logo : logo}
          style={login.style !== undefined ? login.style : styleAll}
        />
      </div>

      {login.userName !== "aliste" &&
        login.link === undefined &&
        login?.deviceId !== undefined &&
        login?.deviceId?.length !== 1 && (
          <div
            style={{
              marginTop: "50px",
              cursor: "pointer",
              display: "flex",
              justifyContent: "center",
            }}
            onClick={() => {
              setLoading(true);
              //   loadMulipleApi(login.deviceId)
              //   navigate(`/dashboard/total`)
              // }}
              let deviceId = login.deviceId.filter((id) => {
                if (id.metered === undefined) {
                  return id;
                }
              });
              if (
                new Date(dateA).setHours(0, 0, 0, 0) !==
                new Date().setHours(0, 0, 0, 0)
              ) {
                loadMulipleDateApi(deviceId, dateA);
                if (id === "M240002") {
                  loadDataofDivice2Day("M250001", dateA);
                }
              } else {
                console.log("today", dateA);
                loadMulipleApi(deviceId);
                if (id === "M240002") {
                  loadDataofDivice2("M250001");
                }
              }
              navigate(`/dashboard/data/total`);
            }}
          >
            <a data-tooltip-id="my-tooltip" data-tooltip-content={`Total`}>
              <DasbordIcon activ={id === "total" ? styles.active : {}} />
            </a>
          </div>
        )}

      {login?.deviceId?.map((d) => {
        if (d === null) return;
        return (
          <div
            style={{
              marginTop: "50px",
              cursor: "pointer",
              display: "flex",
              justifyContent: "center",
            }}
            key={d.deviceId}
            onClick={() => {
              setLoading(true);
              // loadMulipleApi([d])
              // if (d.deviceId === "M240002") {
              //   loadDataofDivice2("M250001")
              // }
              if (
                new Date(dateA).setHours(0, 0, 0, 0) !==
                new Date().setHours(0, 0, 0, 0)
              ) {
                loadMulipleDateApi([{ deviceId: d.deviceId }], dateA);
                if (id === "M240002") {
                  console.log(id, "ddddd id");
                  loadDataofDivice2Day("M250001", dateA);
                }
              } else {
                loadMulipleApi([{ deviceId: d.deviceId }]);
                if (id === "M240002") {
                  loadDataofDivice2("M250001");
                }
              }
              if (d.phase) {
                navigate(`/dashboard/phase/${d.deviceId}`);
              } else {
                navigate(`/dashboard/data/${d.deviceId}`);
              }
            }}

            // }}
          >
            <a data-tooltip-id="my-tooltip" data-tooltip-content={`${d.name}`}>
              {d.image === undefined ? (
                <DasbordIcon activ={d.deviceId === id ? styles.active : {}} d={d}/>
              ) : (
                <div style={d.deviceId === id ? styles.active : {}}>
                  <img
                    src={d.image}
                    style={{
                      height: "55px",
                    }}
                  />
                  <div>{d.showName && d.showName}</div>
                </div>
              )}
            </a>
          </div>
        );
      })}
      <Tooltip id="my-tooltip" />
    </div>
  );
}

export default SideBar;
