import { object } from 'prop-types';
import React, { useEffect, useState } from 'react'
import ReactApexChart from "react-apexcharts";
import { useParams } from 'react-router-dom';

function CarbonConsumeBarChart({barDatas}) {
    // console.log(barDatas,"ddddd");
    let { id } = useParams();
  const [series,setSeries] = useState([])
  const [options,setOptions] = useState({
      
  })
  useEffect(()=>{
    if(barDatas.length===0) return

    let seriesData = []
    // for(let i=0 ; i<barDatas.length ; i++){
    //   if(barDatas[i]===undefined) continue
    // let obj = {name:`Data ${i}`,data:Object.keys(barDatas[i]).length<=1?[]:barDatas[i].data}
    //   seriesData.push(obj)
    // }
     let categories = []
    for(let i of barDatas){
      console.log(i,"cate");
      // if(i===undefined || Object.keys(i).length<=1) continue
      if(i!==undefined && Object.keys(i).length!==0 && i.categories.length!==0){
        categories=i.categories
      }
    }
    setSeries(barDatas)
    setOptions({
      colors :['#1AC821'],
      chart:{
        stacked:true
      },
      plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: '55%',
            endingShape: 'rounded',
  
            borderRadius: 5.556,
            borderRadiusApplication: 'end',
          },
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          show: true,
          width: 2,
          colors: ['transparent']
        },
        xaxis: {
          categories: categories,
        },
        yaxis: {
          title: {
            text: 'kg CO2'
          },
          labels:{
          formatter:function (val) {
              return  `${(val).toFixed(5)} `
            },
          },
        },
        fill: {
          opacity: 1
        },
        tooltip: {
          y: {
            formatter: function (val) {
              return  `${(val).toFixed(5)} kg CO2`
            }
          }
        }
        
    })
  },[barDatas])
  return (
    <div>
        <ReactApexChart
          series={series}
          options={options}
          type={'bar'}
          height={215}
        />
    </div>
  )
}

export default CarbonConsumeBarChart