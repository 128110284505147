import React from 'react'

function TextContainer({ header, subHeader }) {
    const styles = {
        container: {
            display: "flex",
            flexDirection: "column",
            gap: "9px"
        },
        headerText: {
            color: "#363B3F",
            fontFamily: "manrope",
            fontSize: "10.081px",
            fontStyle: "normal",
            fontWeight: 500,
            lineHeight: "7.542px", /* 74.815% */
        },
        subText: {
            color: "#A7AAB1",
            fontFamily: "manrope",
            fontSize: "7.355px",
            fontStyle: "normal",
            fontWeight: 400,
            lineHeight: "5.503px", /* 74.815% */
        }
    }
    return (
        <div style={styles.container}>
            <div style={styles.headerText}>
                {header}
            </div>
            <div style={styles.subText}>
                {subHeader}
            </div>
        </div>
    )
}

export default TextContainer