// export const carbonFromUnits = units => {
//   const carbons = units*0.5;
//   return cab
// }

import store from "../redux";

// export const putPowerInSmartOffSession = (smartOffSessions,loadLogs)=>{
//       let newSmartSessionst =  []
//        for(let session of smartOffSessions){
//           let time = session.start
//           for(let i=0;i<loadLogs.length;i++){
//             if(loadLogs[i].ts>time){
//               if(loadLogs[i-1].p!==0){
//               let obj = {...session,p:loadLogs[i-1]}
//               newSmartSessionst.push(obj)
//               continue
//               }else{
//                 for(let j=i-1;j<0;j--){
//                   if(loadLogs[j].p!==0){
                    
//                   }
//                 }
//               }

//             }
//           }
//        }
// }

export const temperatureData = (temperatureLog)=>{
  let data = []
  for (let i of temperatureLog) {
    if (i.t > 0) {
      let d = [i.ts, i.t];
      data.push(d);
    }
  }
  return data
}

export const powerCompunstion = (loadloag)=>{
  let power =[]
  for(let i of loadloag){
  let data = [i.ts, i.p];
  power.push(data);
  }
  return power
}
function timestampToMinutes(timestamp) {
  const date = new Date(timestamp);
  const hours = date.getHours();
  const minutes = date.getMinutes();
  return hours * 60 + minutes;
}
export const carbonConsumed = (log,id,active,value=1) => {
  
  if (log.length !== 0) {
    const findTime = (time, room, p) => {
      let s = new Date(time.startTime).getHours();
      let e = new Date(time.endTime).getHours();

      if (s === e) {
        room[Math.floor(s)].time += time.endTime - time.startTime;
        let timeDiff = time.endTime - time.startTime;
        let unitConsumed = (p * (timeDiff / (1000 * 60 * 60))) / 1000;
        let unitSaved = ((100 * (timeDiff / (1000 * 60 * 60))) / 1000) - ((p * (timeDiff / (1000 * 60 * 60))) / 1000)
        if (unitConsumed === undefined || isNaN(unitConsumed)) {
          unitConsumed = 0;
        }
        if (unitSaved === undefined || isNaN(unitSaved)) {
          unitSaved = 0;
        }
        room[Math.floor(s)].unitSaved += unitSaved;
        room[Math.floor(s)].unit += unitConsumed;
      } else {
        for (let i = s; i <= e; i++) {
          let slotNumber = Math.floor(i);
          if (i !== e) {
            if (i === s) {
              let newStart = new Date(time.startTime);
              let endTime = new Date(time.startTime).setHours(i + 1, 0, 0, 0);
              room[slotNumber].time += endTime - newStart;
              let timeDiff = endTime - newStart;
              let unitConsumed = (p * (timeDiff / (1000 * 60 * 60))) / 1000;
              let unitSaved = ((100 * (timeDiff / (1000 * 60 * 60))) / 1000) - ((p * (timeDiff / (1000 * 60 * 60))) / 1000)
              if (unitConsumed === undefined || isNaN(unitConsumed)) {
                unitConsumed = 0;
              }
              if (unitSaved === undefined || isNaN(unitSaved)) {
                unitSaved = 0;
              }
              room[Math.floor(s)].unitSaved += unitSaved;
              room[slotNumber].unit += unitConsumed;
            } else {
              let newStart = new Date(time.startTime).setHours(i, 0, 0, 0);
              let endTime = new Date(time.startTime).setHours(i + 1, 0, 0, 0);
              room[slotNumber].time += endTime - newStart;
              let timeDiff = endTime - newStart;
              let unitConsumed = (p * (timeDiff / (1000 * 60 * 60))) / 1000;
              let unitSaved = ((100 * (timeDiff / (1000 * 60 * 60))) / 1000) - ((p * (timeDiff / (1000 * 60 * 60))) / 1000)
              if (unitConsumed === undefined || isNaN(unitConsumed)) {
                unitConsumed = 0;
              }
              if (unitSaved === undefined || isNaN(unitSaved)) {
                unitSaved = 0;
              }
              room[Math.floor(s)].unitSaved += unitSaved;
              room[slotNumber].unit += unitConsumed;
            }
          }
          if (i === e) {
            let newStart = new Date(time.startTime).setHours(e, 0, 0, 0);
            room[slotNumber].time += time.endTime - newStart;
            let timeDiff = time.endTime - newStart;
            let unitConsumed = (p * (timeDiff / (1000 * 60 * 60))) / 1000;
            let unitSaved = ((100 * (timeDiff / (1000 * 60 * 60))) / 1000) - ((p * (timeDiff / (1000 * 60 * 60))) / 1000)
            if (unitConsumed === undefined || isNaN(unitConsumed)) {
              unitConsumed = 0;
            }
            if (unitSaved === undefined || isNaN(unitSaved)) {
              unitSaved = 0;
            }
            room[Math.floor(s)].unitSaved += unitSaved;
            room[slotNumber].unit += unitConsumed;
          }
        }
      }
    };
    let slot = {
      0: {
        time: 0,
        unit: 0,
        devices: [],
        label: "12 AM",
        unitSaved: 0,
      },
      1: {
        time: 0,
        unit: 0,
        devices: [],
        label: "1 AM",
        unitSaved: 0,
      },
      2: {
        time: 0,
        unit: 0,
        devices: [],
        label: "2 AM",
        unitSaved: 0,
      },
      3: {
        time: 0,
        unit: 0,
        devices: [],
        label: "3 AM",
        unitSaved: 0,
      },
      4: {
        time: 0,
        unit: 0,
        devices: [],
        label: "4 AM",
        unitSaved: 0,
      },
      5: {
        time: 0,
        unit: 0,
        devices: [],
        label: "5 AM",
        unitSaved: 0,
      },
      6: {
        time: 0,
        unit: 0,
        devices: [],
        label: "6 AM",
        unitSaved: 0,
      },
      7: {
        time: 0,
        unit: 0,
        devices: [],
        label: "7 AM",
        unitSaved: 0,
      },
      8: {
        time: 0,
        unit: 0,
        devices: [],
        label: "8 AM",
        unitSaved: 0,
      },
      9: {
        time: 0,
        unit: 0,
        devices: [],
        label: "9 AM",
        unitSaved: 0,
      },
      10: {
        time: 0,
        unit: 0,
        devices: [],
        label: "10 AM",
        unitSaved: 0,
      },
      11: {
        time: 0,
        unit: 0,
        devices: [],
        label: "11 AM",
        unitSaved: 0,
      },
      12: {
        time: 0,
        unit: 0,
        devices: [],
        label: "12 PM",
        unitSaved: 0,
      },
      13: {
        time: 0,
        unit: 0,
        devices: [],
        label: "1 PM",
        unitSaved: 0,
      },
      14: {
        time: 0,
        unit: 0,
        devices: [],
        label: "2 PM",
        unitSaved: 0,
      },
      15: {
        time: 0,
        unit: 0,
        devices: [],
        label: "3 PM",
        unitSaved: 0,
      },
      16: {
        time: 0,
        unit: 0,
        devices: [],
        label: "4 PM",
        unitSaved: 0,
      },
      17: {
        time: 0,
        unit: 0,
        devices: [],
        label: "5 PM",
        unitSaved: 0,
      },
      18: {
        time: 0,
        unit: 0,
        devices: [],
        label: "6 PM",
        unitSaved: 0,
      },
      19: {
        time: 0,
        unit: 0,
        devices: [],
        label: "7 PM",
        unitSaved: 0,
      },
      20: {
        time: 0,
        unit: 0,
        devices: [],
        label: "8 PM",
        unitSaved: 0,
      },
      21: {
        time: 0,
        unit: 0,
        devices: [],
        label: "9 PM",
        unitSaved: 0,
      },
      22: {
        time: 0,
        unit: 0,
        devices: [],
        label: "10 PM",
        unitSaved: 0,
      },
      23: {
        time: 0,
        unit: 0,
        devices: [],
        label: "11 PM",
        unitSaved: 0,
      },
    };
  
    for (let i = 0; i <= log.length; i++) {
      if (log[i + 1] !== undefined) {
        if(active){
          if(timestampToMinutes(log[i].ts) >= 600 && timestampToMinutes(log[i].ts) <= 1260){
            if(log[i].p === 0) continue
            findTime(
              { startTime: log[i].ts, endTime: log[i + 1].ts },
              slot,
              log[i].p
            );
          }

        }else{
        findTime(
          { startTime: log[i].ts, endTime: log[i + 1].ts },
          slot,
          log[i].p
        );
        }
      }
    }
    let total = 0;
    let totalCarbonSaved = 0;
    let totalOntime = 0
    for (let i = 0; i <= 23; i++) {
      if (slot[i].unit !== 0) {
        if (slot[i].unit === undefined || isNaN(slot[i].unit)) {
          slot[i].unit = 0;
        }
        total += slot[i].unit;
        totalCarbonSaved += slot[i].unitSaved
        totalOntime += slot[i].time
        slot[i].unit = slot[i].unit * value;
        slot[i].unitSaved = slot[i].unitSaved * value;
      }
    }
    let barData = [];
    let barCategories = [];
    let carbonSavedData = []; 
    let unitConsumedLineChart = {data:[],categories:[]}
    for (let i of Object.keys(slot)) {
      barData.push(slot[i].unit);
      carbonSavedData.push(slot[i].unitSaved);
      barCategories.push(slot[i].label);
      unitConsumedLineChart.data.push(Math.round(slot[i].unit*100)/100)
      unitConsumedLineChart.categories.push(slot[i].label)
    }
    
    return {
      data: barData,
      categories: barCategories,
      totalSaved: total * value,
      unitConsumed: total,
      totalOntime:totalOntime/(1000 * 60 * 60),
      carbonSavedData:carbonSavedData,
      totalCarbonSaved: totalCarbonSaved * value,
      unitConsumedLineChart,
    };
  }
};

export const moneySavedSmartOffSessions = (smartOffSessions, averageP = 2200,display=false,cpu) => {
  if (smartOffSessions.length !== 0) {
    const findTime = (time, room, hrs) => {
      let s = new Date(time.startTime).getHours();
      let e = new Date(time.endTime).getHours();

      if (s === e) {
        room[Math.floor(s/hrs)].time += time.endTime - time.startTime;
      } else {
        for (let i = s; i <= e; i++) {
          let slotNumber = Math.floor(i/hrs);
          if (i !== e) {
            if (i === s) {
              let newStart = new Date(time.startTime);
              let endTime = new Date(time.startTime).setHours(i + 1, 0, 0, 0);
              room[slotNumber].time += endTime - newStart;

              // if (!room[slotNumber].devices.includes(id)) {
              //     room[slotNumber].devices.push(id)
              // }
            } else {
              let newStart = new Date(time.startTime).setHours(i, 0, 0, 0);
              let endTime = new Date(time.startTime).setHours(i + 1, 0, 0, 0);
              room[slotNumber].time += endTime - newStart;
              // if (!room[slotNumber].devices.includes(id)) {
              //     room[slotNumber].devices.push(id)
              // }
            }
          }
          if (i === e) {
            let newStart = new Date(time.startTime).setHours(e, 0, 0, 0);
            room[slotNumber].time += time.endTime - newStart;
            // if (!room[slotNumber].devices.includes(id)) {
            //     room[slotNumber].devices.push(id)
            // }
          }
        }
      }
    };
    let slot3hr = {
      0: {
        time: 0,
        devices: [],
        label: "12 AM",
      },
      1: {
        time: 0,
        devices: [],
        label: "3 AM",
      },
      2: {
        time: 0,
        devices: [],
        label: "6 AM",
      },
      3: {
        time: 0,
        devices: [],
        label: "9 AM",
      },
      4: {
        time: 0,
        devices: [],
        label: "12 PM",
      },
      5: {
        time: 0,
        devices: [],
        label: "3 PM",
      },
      6: {
        time: 0,
        devices: [],
        label: "6 PM",
      },
      7: {
        time: 0,
        devices: [],
        label: "9 PM",
      },
    };
    let slot = {
      0: {
        time: 0,
        unit: 0,
        devices: [],
        label: "12 AM",
      },
      1: {
        time: 0,
        unit: 0,
        devices: [],
        label: "1 AM",
      },
      2: {
        time: 0,
        unit: 0,
        devices: [],
        label: "2 AM",
      },
      3: {
        time: 0,
        unit: 0,
        devices: [],
        label: "3 AM",
      },
      4: {
        time: 0,
        unit: 0,
        devices: [],
        label: "4 AM",
      },
      5: {
        time: 0,
        unit: 0,
        devices: [],
        label: "5 AM",
      },
      6: {
        time: 0,
        unit: 0,
        devices: [],
        label: "6 AM",
      },
      7: {
        time: 0,
        unit: 0,
        devices: [],
        label: "7 AM",
      },
      8: {
        time: 0,
        unit: 0,
        devices: [],
        label: "8 AM",
      },
      9: {
        time: 0,
        unit: 0,
        devices: [],
        label: "9 AM",
      },
      10: {
        time: 0,
        unit: 0,
        devices: [],
        label: "10 AM",
      },
      11: {
        time: 0,
        unit: 0,
        devices: [],
        label: "11 AM",
      },
      12: {
        time: 0,
        unit: 0,
        devices: [],
        label: "12 PM",
      },
      13: {
        time: 0,
        unit: 0,
        devices: [],
        label: "1 PM",
      },
      14: {
        time: 0,
        unit: 0,
        devices: [],
        label: "2 PM",
      },
      15: {
        time: 0,
        unit: 0,
        devices: [],
        label: "3 PM",
      },
      16: {
        time: 0,
        unit: 0,
        devices: [],
        label: "4 PM",
      },
      17: {
        time: 0,
        unit: 0,
        devices: [],
        label: "5 PM",
      },
      18: {
        time: 0,
        unit: 0,
        devices: [],
        label: "6 PM",
      },
      19: {
        time: 0,
        unit: 0,
        devices: [],
        label: "7 PM",
      },
      20: {
        time: 0,
        unit: 0,
        devices: [],
        label: "8 PM",
      },
      21: {
        time: 0,
        unit: 0,
        devices: [],
        label: "9 PM",
      },
      22: {
        time: 0,
        unit: 0,
        devices: [],
        label: "10 PM",
      },
      23: {
        time: 0,
        unit: 0,
        devices: [],
        label: "11 PM",
      },
    };
    for (let i of smartOffSessions) {
      let obj = { startTime: i.start, endTime: i.end };
      findTime(obj,display?slot3hr:slot,display?3:1);
    }
    let selectedSlot = display?slot3hr:slot
    let barData = [];
    let barCategories = [];
    let totalOffTime = 0;


    for (let i of Object.keys(selectedSlot)) {
   
      barData.push((((selectedSlot[i].time / 3600000) * averageP) / 1000) * cpu);
      barCategories.push(selectedSlot[i].label);
    }

    for (let i of barData) {
      totalOffTime += Number(i);
    }
    return {
      data: barData,
      categories: barCategories,
      totalSaved: totalOffTime,
    };
  } else {
    return {};
  }
};

export const savedVoltageSmartOffSessions = (smartOffSessions) => {};

export const carbonFootprintSaved = (smartOffSessions, averageP,display=false,value=1) => {
  
  if (smartOffSessions.length !== 0) {
    let total = 0;
    for (let i = 0; i < smartOffSessions.length; i += 1) {
      total += smartOffSessions[i].end - smartOffSessions[i].start;
    }
    // console.log(total, total / (60*60*1000), "smart off sessions")
    const findTime = (time, room,hrs) => {
      let s = new Date(time.startTime).getHours();
      let e = new Date(time.endTime).getHours();

      if (s === e) {
        room[Math.floor(s/hrs)].time += time.endTime - time.startTime;
      } else {
        for (let i = s; i <= e; i++) {
          let slotNumber = Math.floor(i/hrs);
          if (i !== e) {
            if (i === s) {
              let newStart = new Date(time.startTime);
              let endTime = new Date(time.startTime).setHours(i + 1, 0, 0, 0);
              room[slotNumber].time += endTime - newStart;
            } else {
              let newStart = new Date(time.startTime).setHours(i, 0, 0, 0);
              let endTime = new Date(time.startTime).setHours(i + 1, 0, 0, 0);
              room[slotNumber].time += endTime - newStart;
            }
          }
          if (i === e) {
            let newStart = new Date(time.startTime).setHours(e, 0, 0, 0);
            room[slotNumber].time += time.endTime - newStart;
          }
        }
      }
    };
    let slot3hr = {
      0: {
        time: 0,
        devices: [],
        label: "12 AM",
      },
      1: {
        time: 0,
        devices: [],
        label: "3 AM",
      },
      2: {
        time: 0,
        devices: [],
        label: "6 AM",
      },
      3: {
        time: 0,
        devices: [],
        label: "9 AM",
      },
      4: {
        time: 0,
        devices: [],
        label: "12 PM",
      },
      5: {
        time: 0,
        devices: [],
        label: "3 PM",
      },
      6: {
        time: 0,
        devices: [],
        label: "6 PM",
      },
      7: {
        time: 0,
        devices: [],
        label: "9 PM",
      },
    };
    let slot = {
      0: {
        time: 0,
        unit: 0,
        devices: [],
        label: "12 AM",
      },
      1: {
        time: 0,
        unit: 0,
        devices: [],
        label: "1 AM",
      },
      2: {
        time: 0,
        unit: 0,
        devices: [],
        label: "2 AM",
      },
      3: {
        time: 0,
        unit: 0,
        devices: [],
        label: "3 AM",
      },
      4: {
        time: 0,
        unit: 0,
        devices: [],
        label: "4 AM",
      },
      5: {
        time: 0,
        unit: 0,
        devices: [],
        label: "5 AM",
      },
      6: {
        time: 0,
        unit: 0,
        devices: [],
        label: "6 AM",
      },
      7: {
        time: 0,
        unit: 0,
        devices: [],
        label: "7 AM",
      },
      8: {
        time: 0,
        unit: 0,
        devices: [],
        label: "8 AM",
      },
      9: {
        time: 0,
        unit: 0,
        devices: [],
        label: "9 AM",
      },
      10: {
        time: 0,
        unit: 0,
        devices: [],
        label: "10 AM",
      },
      11: {
        time: 0,
        unit: 0,
        devices: [],
        label: "11 AM",
      },
      12: {
        time: 0,
        unit: 0,
        devices: [],
        label: "12 PM",
      },
      13: {
        time: 0,
        unit: 0,
        devices: [],
        label: "1 PM",
      },
      14: {
        time: 0,
        unit: 0,
        devices: [],
        label: "2 PM",
      },
      15: {
        time: 0,
        unit: 0,
        devices: [],
        label: "3 PM",
      },
      16: {
        time: 0,
        unit: 0,
        devices: [],
        label: "4 PM",
      },
      17: {
        time: 0,
        unit: 0,
        devices: [],
        label: "5 PM",
      },
      18: {
        time: 0,
        unit: 0,
        devices: [],
        label: "6 PM",
      },
      19: {
        time: 0,
        unit: 0,
        devices: [],
        label: "7 PM",
      },
      20: {
        time: 0,
        unit: 0,
        devices: [],
        label: "8 PM",
      },
      21: {
        time: 0,
        unit: 0,
        devices: [],
        label: "9 PM",
      },
      22: {
        time: 0,
        unit: 0,
        devices: [],
        label: "10 PM",
      },
      23: {
        time: 0,
        unit: 0,
        devices: [],
        label: "11 PM",
      },
    };
  
    for (let i of smartOffSessions) {
      let obj = { startTime: i.start, endTime: i.end };
      findTime(obj, display?slot3hr:slot,display?3:1);
    }
    let barData = [];
    let barCategories = [];
    let totalOffTime = 0;
    let selectedSlot = display ? slot3hr: slot
    for (let i of Object.keys(selectedSlot)) {
      barData.push((((selectedSlot[i].time / (1000 * 60 * 60)) * averageP)) * value);
      barCategories.push(selectedSlot[i].label);
    }

    for (let i of barData) {
      totalOffTime += Number(i);
    }

    return {
      data: barData,
      categories: barCategories,
      totalSaved: totalOffTime,
    };
  } else {
    return {};
  }
};


export const averagePower = (loadLogs) => {
  
  let totalTime = 0;
  let weightedSum = 0;
  for (let i = 0; i < loadLogs.length - 1; i += 1) {
    if (loadLogs[i].p !== 0) {
      let time = loadLogs[i + 1].ts - loadLogs[i].ts;
      let power = loadLogs[i].p;
      weightedSum += (power*time);
      totalTime += time; 
    }
  }
  const weightedAveragePower = weightedSum/totalTime; 
  // console.log(weightedAveragePower, 'weightedAveragePower');
  return weightedAveragePower;
}