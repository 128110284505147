import moment from "moment";
import { useEffect, useState } from "react"
import ReactApexChart from "react-apexcharts";

export default function IntervlLineChart({seriesData,degree}) {
    console.log(seriesData,"rrrr");
      const [series,setSeries] = useState([])
      const [options,setOptions] = useState({})

      useEffect(()=>{
         setSeries(seriesData)
         let categories = []
         for(let i of seriesData){
           console.log(i,"cate");
           // if(i===undefined || Object.keys(i).length<=1) continue
           if(i!==undefined && Object.keys(i).length!==0 && i.categories.length!==0){
             categories=i.categories
           }
         }
        setOptions( {
            chart: {
            height: 350,
            type: 'line',
            zoom: {
              enabled: false
            }
          },
          dataLabels: {
            enabled: false
          },
          stroke: {
            curve: 'straight'
          },
        
          grid: {
            row: {
              colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
              opacity: 0.5
            },
          },
          xaxis: {
            categories: categories,
          }
          })
      },[seriesData])
      

  


      return (
        
            <ReactApexChart options={options} series={series} type="line" height={215} />
      );
    }
  
