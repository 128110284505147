import axios from "axios"
import { serverUrl } from "../../ApiRoute"
import store from "../redux"
import { messages } from "./notification"
import { loadMulipleApi } from "../redux/action/totalData"

export const deviceControl = (deviceId,command)=>{
    
    axios.post(`${serverUrl.aws}/control`,{
        "deviceId": deviceId,
        "switchId": 0,
        "command": command,
        "id": new Date().getTime().toString().slice(5,13),
        "controllerId": store.getState().login.userName,
        "controllerType": "autoOn",
        "control": true
    }).then(()=>{
         loadMulipleApi([{deviceId:deviceId}])
    }).catch(()=>{
        messages.error("Something went wrong")
    })
}
export const deviceDetails = async(id)=>{
    
   return await axios.post(`${serverUrl.aws}/details`,{
    "deviceId": id
    }).then(res=>{
        if(res.data.success){
            if(Object.keys(res.data.data).length>0){
            return res.data.data.device
            }else{
                return {}
            }
        }else{
            return {}
        }
    }).catch(err=>{
        return {}
    })
}

export const autoTimersStart = async(id)=>{
    
    return await axios.post(`${serverUrl.aws}/autoTimers/start`,{
        "deviceId": id,
        "control":  true,
        "command": 100
        }).then(res=>{
            loadMulipleApi([{deviceId:id}])
            if(res.data.success){
                return res.data.data.device
            }else{
                return {}
            }
        }).catch(err=>{
            return {}
        })
}

export const autoTimerStop = async(id)=>{
    return await axios.post(`${serverUrl.aws}/autoTimers/stop`,{
        "deviceId": id,
        "control":  true,
        "command": 0
        }).then(res=>{
            loadMulipleApi([{deviceId:id}])
            if(res.data.success){
                return {}
            }else{
                return {}
            }
        }).catch(err=>{
            return {}
        })
}