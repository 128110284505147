
import {createStore,combineReducers} from 'redux'
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage' 
import { todayDataReducer ,todayDataOfDeviceReducer} from './reducer/TodayData'
import { loginDetailReducer } from './reducer/login'
import { loadMultipleDataReducer } from './reducer/totalData'

const persistConfig = {
    key: 'persist-key',
    storage
}

const persistedReducer = persistReducer(persistConfig,combineReducers({
   todayData:todayDataReducer,
   todayData2:todayDataOfDeviceReducer,
   login:loginDetailReducer,
   mulipleData:loadMultipleDataReducer
}))

let store = createStore(persistedReducer);
let persistor = persistStore(store)
export default store;
export {persistor}