import moment from "moment";
import { useEffect, useState } from "react"
import ReactApexChart from "react-apexcharts";

export default function LineChart({seriesData,degree}) {
      const [series,setSeries] = useState([])
      const [options,setOptions] = useState({
       
        chart: {
          type: 'area',
          stacked: false,
          height: "215px",
          zoom: {
            type: 'x',
            enabled: true,
            autoScaleYaxis: true
          },
          toolbar: {
            autoSelected: 'zoom'
          }
        },
        dataLabels: {
          enabled: false
        },
      
        markers: {
          size: 0,
        },
        title: {
          text: '',
          align: 'left'
        },
      
        yaxis: {
          labels: {
            formatter: function (val) {
              return (val).toFixed(0);
            },
          },
          title: {
            text: degree
          },
        },
        xaxis: {
          type: 'datetime',
          labels: {
            // format: 'yyyy-MM-dd HH:mm',
            formatter: function (value, timestamp) {
                return new Date(timestamp).toLocaleString('en-us',{
                   
                        // year: 'numeric',
                        // month: 'long',
                        // day: 'numeric',
                        hour: 'numeric',
                        minute: 'numeric',
                        // second: 'numeric',
                        // timeZoneName: 'short'
                      
                }) // The formatter function overrides format property
              },
          }
        },
        tooltip: {
          shared: false,
          x: {
            formatter: function (val) {
              return (new Date(val).toLocaleString('en-us',{
                   
                // year: 'numeric',
                // month: 'long',
                // day: 'numeric',
                hour: 'numeric',
                minute: 'numeric',}) )
            },
        },
          y: {
            formatter: function (val) {
              return (val )
            }
          }
        }
      })

      useEffect(()=>{
        if(seriesData.length===0) return
    let seriesDataNew = []
         setSeries(seriesData)
        setOptions({
          // colors:['#322FC8'],
          chart: {
            type: 'area',
            stacked: false,
            height: "215px",
            zoom: {
              type: 'x',
              enabled: true,
              autoScaleXaxis: true
            },
            toolbar: {
              autoSelected: 'zoom'
            }
          },
          dataLabels: {
            enabled: false
          },
        
          markers: {
            size: 0,
          },
          title: {
            text: '',
            align: 'left'
          },
        
          yaxis: {
            labels: {
              formatter: function (val) {
                return (val).toFixed(0);
              },
            },
            title: {
              text: degree
            },
          },
          xaxis: {
            type: 'datetime',
            labels: {
              // format: 'yyyy-MM-dd HH:mm',
              formatter: function (value, timestamp) {
                  return new Date(timestamp).toLocaleString('en-us',{
                     
                          // year: 'numeric',
                          // month: 'long',
                          // day: 'numeric',
                          hour: 'numeric',
                          minute: 'numeric',
                          // second: 'numeric',
                          // timeZoneName: 'short'
                        
                  }) // The formatter function overrides format property
                },
            }
          },
          tooltip: {
            shared: false,
            x: {
              formatter: function (val) {
                return (new Date(val).toLocaleString('en-us',{
                     
                  // year: 'numeric',
                  // month: 'long',
                  // day: 'numeric',
                  hour: 'numeric',
                  minute: 'numeric',}) )
              },
          },
            y: {
              formatter: function (val) {
                return (val )
              }
            }
          }
        })
      },[seriesData])
      

  


      return (
        
            <ReactApexChart options={options} series={series} type="line" height={215} />
      );
    }
  
