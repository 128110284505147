import React, { useEffect, useState } from "react";
import DetailContainer from "./common/DetailContainer";
import { useSelector } from "react-redux";
import LineChart from "../Chart/LineChart";
import ChartHeader from "../Chart/ChartHeader";
import PieChart from "../Chart/PieChart";
import BarChart from "../Chart/BarChart";
import SingleValuePieChart from "../Chart/SingleValuePieChart";
import TextContainer from "./common/TextContainer";
import { images } from "../common/images";
import { user } from "../../Login/Login";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import {
  loadData,
  loadDataDay,
  loadDataofDivice2,
  loadDataofDivice2Day,
  loadDataofDiviceWithStartEndTime,
} from "../redux/action/TodatData";
import { useNavigate, useParams } from "react-router-dom";
import { Col, Form, Row } from "react-bootstrap";
import { Grid, TextField, Button } from "@mui/material";
import {
  averageP,
  averagePower,
  carbonConsumed,
  carbonFootprintSaved,
  moneySavedSmartOffSessions,
  powerCompunstion,
  temperatureData,
} from "../common/function";
import CarbonConsumeBarChart from "../Chart/CarbonConsumeBarChart";
import MoneySavedBar from "../Chart/MoneySavedBar";
import {
  getRandomIntegerInclusive,
  moneySavedChart,
  onlineOffline,
  totalCarbonConsume,
} from "../common/util";
import {
  loadMulipleApi,
  loadMulipleDateApi,
  loadMulipleWithStartEndTimeApi,
} from "../redux/action/totalData";
import moment from "moment";
import { messages } from "../common/notification";
import {
  autoTimerStop,
  autoTimersStart,
  deviceControl,
  deviceDetails,
} from "../common/ApiCall";
import { Toaster } from "react-hot-toast";
import { Cursor } from "react-bootstrap-icons";
import IntervlLineChart from "../Chart/IntervlLineChart";
import { totalPhasePowerConsumption } from "../common/phaseFunction";
import { da } from "date-fns/locale";

const styles = {
  container: {
    background: "#F5F7FA",
    height: "90vh",
    width: "100%",
    paddingTop: "2%",
    paddingRight: "2%",
    paddingLeft: "2%",
    overflowY: "scroll",
  },
  smallBoxContainer: {
    marginTop: "20px",
  },
  chartContainer: {
    width: "100%",
    height: "317px",
    background: "#FFFFFF",
    borderRadius: "14px",
    marginTop: "2%",
    padding: "24px",
  },
  smallPieContainer: {
    display: "flex",
    alignItems: "center",
    gap: "10px",
  },
};
function MainV2({ setLoading, dateA, setDateA }) {
  let { id } = useParams();
  const navigate = useNavigate();
  // let toadyData = useSelector((state) => state.todayData);
  let toadyData2 = useSelector((state) => state.todayData2);
  let mulipleData = useSelector((state) => state.mulipleData);
  const login = useSelector((state) => state.login);
  const [deviceDetail, setDeviceDetails] = useState({});
  const [value, setValue] = useState(null);
  const [todayTempertureLog, setTodayTempertureLog] = useState([]);
  const [onTime, setOnTime] = useState([]);
  const [carbonConsumedBar, setCarbonConsumedBar] = useState({});
  const [moneySavedBar, setMoneySavedBar] = useState({});
  const [carbonSaved, setCarbonSaved] = useState({});
  const [power, setPower] = useState([]);
  const [power2, setPower2] = useState([]);
  const [barDatas, setBarData] = useState({});
  const [chartData, setChartData] = useState({});
  const [deviceId, setDeviceId] = useState(id);
  const [details, setDetails] = useState({
    startTime: "",
    endTime: "",
    deviceId: id,
  });

  // new State
  const [globalSavingData, setGlobalSavingData] = useState({
    globalMoneySaved: 0,
    globalMoneySpent: 0,
    globalUnitSaved: 0,
    globalUnitConsumed: 0,
    globalUnitSavingPercentge: 0,
  });
  const [globalMoneySavedChartData, setGlobalMoneySavedChartData] = useState(
    []
  );
  const [globalCarbonConsumeChartData, setGlobalCarbonConsumeChartData] =
    useState([]);
  const [globalCarbonSavedChartData, setGlobalCarbonSavedChartData] = useState(
    []
  );
  const [globalUnitNewConsumeChartData, setGlobalUnitNewConsumeChartData] =
    useState([]);
  const [globalUnitNewSavedChartData, setGlobalUnitNewSavedChartData] =
    useState([]);
  const [globalTemperatureChartData, setGlobalTemperatureChartData] = useState(
    []
  );
  const [globalPowerConsumtionChartData, setGlobalPowerConsumtionChartData] =
    useState([]);
  const [globalUnitConsumedLine, setGlobalUnitConsumedLine] = useState([]);
  const [phaseLoadLogs, setPhaseLoadLogs] = useState({});
  const threeHour = ["habitat"];
  const portelStatus = ["habitat"];
  const OnlineStatus = ["habitat"];
  const chartNotIncluse = ["M280014", "M280011", "M280010", "M280013"];
  const unitGraph = ["TATA_AIG"];
  const unitTab = ["TATA_AIG"];
  const moneySpentNotShow=["TATA_AIG"]
  const chartShow = [
    "yourspace",
    "wiley",
    "campus",
    "campus2",
    "oceans",
    "vietnom",
    "paras",
    "bata",
    "steelo",
    "grandcentral",
    "sarthakpalace",
    "alankaram",
    "ARS",
    "rajiv",
    "bikanerwala",
    "aliste",
    "demo",
    "stanza",
    "chetanya",
    "rishankDabra",
    "rahulmuskan",
    "rajkamalPlywood",
    "nulifeHospital",
    "hansHospital",
    "HOTEL ROSEMAN",
    "Raman Kumar",
    "Sachin Kumar",
    "Royal Orchid",
    "Steelo Brite",
    "test",
    "bata.bengaluru",
    "careerlauncher",
    "9711697119",
    "veggulati",
    "krishna novelties",
    "Suman Mittal",
    "Maninder",
    "platinumInn",
    "platinumResidency",
    "Settl Verona",
    "le sutra",
    "looksSaloon",
    "subway",
  ];
  const chartNotShow = [
    "yourspace",
    "wiley",
    "campus",
    "campus2",
    "oceans",
    "vietnom",
    "paras",
    "bata",
    "steelo",
    "sarthakpalace",
    "ARS",
    "demo",
    "alankaram",
    "rajiv",
    "laffaire",
    "bikanerwala",
    "aliste",
    "stanza",
    "chetanya",
    "rishankDabra",
    "rahulmuskan",
    "rajkamalPlywood",
    "nulifeHospital",
    "hansHospital",
    "HOTEL ROSEMAN",
    "Raman Kumar",
    "Sachin Kumar",
    "Royal Orchid",
    "Steelo Brite",
    "test",
    "bata.bengaluru",
    "careerlauncher",
    "9711697119",
    "veggulati",
    "krishna novelties",
    "Suman Mittal",
    "Maninder",
    "platinumInn",
    "platinumResidency",
    "Settl Verona",
    "le sutra",
    "looksSaloon",
    "subway",
  ];

  const autoCutShow = ["aliste", "laffaire", "steelo", "rajiv"];
  const onlyMoniter = [
    "krishna novelties",
    "Maninder",
    "Suman Mittal",
    "le sutra",
    'TATA_AIG'
  ];
  const randomSaving = ["bikanerwala"];
  const temperatureDataNotShow = [
    "bata.bengaluru",
    "bata",
    "campus",
    "campus2",
    "vietnom",
    "Suman Mittal",
    "le sutra",
    'TATA_AIG'
  ];
  const applianceHealth = ["Maninder", "Suman Mittal"];
  let randomSavingNumber = getRandomIntegerInclusive(7, 11);
  useEffect(() => {
    if (id !== "total") {
      deviceDetails(id)
        .then((res) => {
          setDeviceDetails(res);
        })
        .catch((err) => {
          setDeviceDetails({});
        });
    }
  }, [mulipleData]);
  useEffect(() => {
    if (mulipleData.length === 0) return;
    setLoading(true);
    let cpu = login.cpu !== undefined ? login.cpu : 15;
    let savingMultiplayer = 1;
    if (login.random !== undefined && login.random === true) {
      savingMultiplayer = getRandomIntegerInclusive(7, 11);
    }
    console.log(savingMultiplayer, savingMultiplayer);
    let globalCarbonConsumedData = [];
    let globalUnitNewConsumedData = [];
    let globalCarbonSavedData = [];
    let globalUnitNewSavedData = [];
    let globalMoneySavedData = [];
    let globalTemperatureLogData = [];
    let globalPowerConsumtion = [];

    let globalMoneySpent = 0;
    let globalMoneySaved = 0;
    let globalUnitConsumed = 0;
    let globalUnitSaved = 0;
    let globalUnitSavingPercentge = 0;
    let unitConsumeLineChart = [];
    let phaseLoadLogss = {};
    for (let data of mulipleData) {
      if (Object.keys(data).length === 0) continue;
      let active = false;
      let findActive = login.deviceId.find((d) => {
        if (d?.active && d.deviceId === data.deviceId) return d;
      });

      if (findActive) {
        active = true;
      }

      let loadLogsSort = data.loadLogs.sort(
        (a, b) => parseFloat(a.ts) - parseFloat(b.ts)
      );
      if (loadLogsSort.length === 0) {
        continue;
      }
      let shortAccordingM = {
        0: [],
      };
      for (let p of loadLogsSort) {
        if (p.m === undefined || p.m === 0) {
          shortAccordingM["0"].push(p);
        } else {
          if (shortAccordingM[`${p.m}`] === undefined) {
            shortAccordingM[`${p.m}`] = [];
          }
          shortAccordingM[`${p.m}`].push(p);
        }
      }
      // phaseLoadLogss = { ...phaseLoadLogss, [data.deviceId]: shortAccordingM };
      let temperatureLogSort = data.temperatureLogs.sort(
        (a, b) => parseFloat(a.ts) - parseFloat(b.ts)
      );

      // Temperature Log
      let temp = temperatureData(temperatureLogSort);

      globalTemperatureLogData.push({ data: temp, name: data.deviceId });

      // Average Power
      let averageP =
        login.userName === user[1].userName
          ? 2200
          : login.powerMuliplyier !== undefined
          ? averagePower(data.loadLogs) * login.powerMuliplyier
          : averagePower(data.loadLogs);

      // carbon Consume
      for (let i of Object.keys(shortAccordingM)) {
        // Power Consmption
        let power = powerCompunstion(shortAccordingM[i]);
        console.log(power, "poer");
        globalPowerConsumtion.push({
          data: power,
          name: `${data.deviceId}-Phase ${Number(i) + 1}`,
        });
        let carbonConsumedData = carbonConsumed(
          shortAccordingM[i],
          data.deviceId,
          active,
          0.83
        );
        let unitNewConsumedData = carbonConsumed(
          shortAccordingM[i],
          data.deviceId,
          active,
          0.83
        );
        globalCarbonConsumedData.push({
          name: `${data.deviceId}-Phase ${Number(i) + 1}`,
          data:
            Object.keys(carbonConsumedData).length <= 1
              ? []
              : carbonConsumedData.data,
          categories:
            Object.keys(carbonConsumedData).length <= 1
              ? []
              : carbonConsumedData.categories,
          totalSaved: carbonConsumedData.totalSaved,
        });
        globalUnitNewConsumedData.push({
          name: `${data.deviceId}-Phase ${Number(i) + 1}`,
          data:
            Object.keys(unitNewConsumedData).length <= 1
              ? []
              : unitNewConsumedData.data,
          categories:
            Object.keys(unitNewConsumedData).length <= 1
              ? []
              : unitNewConsumedData.categories,
          totalSaved: unitNewConsumedData.unitConsumed,
        });
        if (shortAccordingM[i].length > 0) {
          unitConsumeLineChart.push({
            ...carbonConsumedData.unitConsumedLineChart,
            totalSaved: carbonConsumedData.unitConsumed,
            name: `${data.deviceId}-Phase ${Number(i) + 1}`,
          });
        }
        let carbonConsumedSaved = {};
        let unitNewSaved = {};
        if (active) {
          carbonConsumedSaved = {
            data: carbonConsumedData.carbonSavedData,
            categories: carbonConsumedData.categories,
            totalSaved: carbonConsumedData.totalCarbonSaved,
          };
          unitNewSaved = {
            data: unitNewConsumedData.carbonSavedData,
            categories: unitNewConsumedData.categories,
            totalSaved: unitNewConsumedData.totalCarbonSaved,
          };
        } else {
          carbonConsumedSaved = carbonFootprintSaved(
            data.smartOffSessions,
            averageP / 1000,
            threeHour.includes(login.userName) ? true : false,
            0.83
          );
          unitNewSaved = carbonFootprintSaved(
            data.smartOffSessions,
            averageP / 1000,
            threeHour.includes(login.userName) ? true : false,
            1
          );
        }
        globalCarbonSavedData.push({
          name: `${data.deviceId}-Phase ${Number(i) + 1}`,
          data:
            Object.keys(carbonConsumedSaved).length <= 1
              ? []
              : carbonConsumedSaved.data,
          categories:
            Object.keys(carbonConsumedSaved).length <= 1
              ? []
              : carbonConsumedSaved.categories,
          totalSaved: carbonConsumedSaved.totalSaved,
        });
        globalUnitNewSavedData.push({
          name: `${data.deviceId}-Phase ${Number(i) + 1}`,
          data: Object.keys(unitNewSaved).length <= 1 ? [] : unitNewSaved.data,
          categories:
            Object.keys(unitNewSaved).length <= 1
              ? []
              : unitNewSaved.categories,
          totalSaved: unitNewSaved.totalSaved,
        });
        // Total Off time
        let totalOffTime = 0;
        for (let i of data.smartOffSessions) {
          totalOffTime += (i.end - i.start) / 3600000;
        }
        // Saved
        let unitSaved = (averageP * totalOffTime) / 1000;
        let unitConsumed = Number(unitNewConsumedData?.unitConsumed.toFixed(2));
console.log(unitConsumed);
        let moneySaved = unitSaved * cpu;
        let moneySpent = unitConsumed * cpu;
        let unitSavingPercentage = unitSaved / (unitConsumed + unitSaved);
        if (active) {
          unitSaved =
            (100 * carbonConsumedData.totalOntime) / 1000 - unitConsumed;
          moneySaved = unitSaved * cpu;
          unitSavingPercentage =
            unitSaved / ((100 * carbonConsumedData.totalOntime) / 1000);
        }
        if (!isNaN(unitSaved)) {
          globalUnitSaved += unitSaved;
        }

        if (!isNaN(unitConsumed)) {
          globalUnitConsumed += unitConsumed;
        }
        if (!isNaN(moneySaved)) {
          globalMoneySaved += moneySaved;
        }
        if (!isNaN(moneySpent)) {
          globalMoneySpent += moneySpent;
        }
        if (!isNaN(unitSavingPercentage)) {
          globalUnitSavingPercentge += unitSavingPercentage;
        }
        phaseLoadLogss = {
          ...phaseLoadLogss,
          [data.deviceId]: {
            ...phaseLoadLogss[data.deviceId],
            [i]: { data: shortAccordingM[i], unit: unitConsumed },
          },
        };
      }

      // Money Saced Bar Chart Data
      let resMoneySaved = moneySavedSmartOffSessions(
        data.smartOffSessions,
        averageP,
        threeHour.includes(login.userName) ? true : false,
        cpu
      );
      globalMoneySavedData.push(resMoneySaved);
    }
    // if (mulipleData.length === 1) {
    //   //on Time
    //   let startDate = new Date().setHours(0, 0, 0, 0);
    //   let endTime = new Date();
    //   let totalTime = endTime - startDate;
    //   let offTime = Math.round(totalTime - mulipleData[0].ontime);
    //   let ontime = Math.round(mulipleData[0].ontime);
    //   let on = (ontime / totalTime) * 100;
    //   let off = (offTime / totalTime) * 100;
    //   setChartData({
    //     OnTime: Math.round(on),
    //     OffTime: Math.round(off),
    //   });
    //   setOnTime([ontime, offTime]);
    // }

    setGlobalSavingData({
      globalMoneySaved:
        isNaN(globalMoneySaved) || globalMoneySaved === Infinity
          ? 0
          : globalMoneySaved,
      globalMoneySpent:
        isNaN(globalMoneySpent) || globalMoneySpent === Infinity
          ? 0
          : globalMoneySpent,
      globalUnitConsumed:
        isNaN(globalUnitConsumed) || globalUnitConsumed === Infinity
          ? 0
          : globalUnitConsumed,
      globalUnitSaved:
        isNaN(globalUnitSaved) || globalUnitSaved === Infinity
          ? 0
          : globalUnitSaved,
      globalUnitSavingPercentge:
        isNaN(globalUnitSavingPercentge) ||
        globalUnitSavingPercentge === Infinity
          ? 0
          : globalUnitSavingPercentge,
    });

    // state
    setGlobalCarbonConsumeChartData(globalCarbonConsumedData);
    setGlobalCarbonSavedChartData(globalCarbonSavedData);
    setGlobalUnitNewConsumeChartData(globalUnitNewConsumedData);
    setGlobalUnitNewSavedChartData(globalUnitNewSavedData);
    setGlobalUnitConsumedLine(unitConsumeLineChart);
    setGlobalMoneySavedChartData(globalMoneySavedData);
    setGlobalTemperatureChartData(globalTemperatureLogData);
    setGlobalPowerConsumtionChartData(globalPowerConsumtion);
    setPhaseLoadLogs(phaseLoadLogss);
    setLoading(false);
  }, [mulipleData]);

  useEffect(() => {
    if (Object.keys(toadyData2).length !== 0 && id === "M240002") {
      let p2 = [];
      let maxP2 = 0;
      if (toadyData2.loadLogs.length !== 0) {
        let loadLogsSort = toadyData2.loadLogs.sort(
          (a, b) => parseFloat(a.ts) - parseFloat(b.ts)
        );

        for (let i of loadLogsSort) {
          if (maxP2 < i.p) {
            maxP2 = i.p;
          }
          let data = [i.ts, i.p];
          p2.push(data);
        }
      }
      setPower2({ data: p2 });
    }
  }, [toadyData2]);
  const today = new Date();
  // Set yesterday's date
  const yesterday = new Date(today);
  yesterday.setDate(yesterday.getDate() - 1);

  return (
    <div style={{ ...styles.container }}>
      <Toaster />
      <Grid container spacing={1}>
        <Grid item xs={8} md={3}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DemoContainer components={["DatePicker"]}>
              <DatePicker
                disableFuture
                disableHighlightToday
                value={value}
                onChange={(newValue) => {
                  setLoading(true);
                  let d = new Date(newValue);
                  if (login.userName !== "aliste") {
                    if (
                      new Date(d).setHours(0, 0, 0, 0) !==
                      new Date().setHours(0, 0, 0, 0)
                    ) {
                      if (id === "total") {
                        setDateA(newValue.$d);
                        loadMulipleDateApi(login.deviceId, newValue.$d);
                      } else {
                        setDateA(newValue.$d);
                        loadMulipleDateApi([{ deviceId: id }], newValue.$d);
                      }
                      if (id === "M240002") {
                        setDateA(newValue.$d);
                        loadDataofDivice2Day("M250001", newValue.$d);
                      }
                    } else {
                      if (id === "total") {
                        setDateA(newValue.$d);
                        loadMulipleApi(login.deviceId);
                      } else {
                        setDateA(newValue.$d);
                        loadMulipleApi([{ deviceId: id }]);
                      }
                      if (id === "M240002") {
                        setDateA(newValue.$d);
                        loadDataofDivice2("M250001");
                      }
                    }
                  } else {
                    setDateA(newValue.$d);
                  }
                }}
              />
            </DemoContainer>
          </LocalizationProvider>
        </Grid>
        {login.userName === "aliste" && (
          <>
            <Grid item xs={8} md={2} sx={{ marginTop: "8px" }}>
              <TextField
                id="outlined-basic"
                label="Device id"
                variant="outlined"
                onChange={(e) => setDeviceId(e.target.value)}
                value={deviceId}
              />
            </Grid>
            <Grid item xs={8} md={2} sx={{ marginTop: "8px" }}>
              <Button
                variant="contained"
                size="large"
                onClick={() => {
                  navigate(`/dashboard/data/${deviceId}`);
                  if (
                    new Date(dateA).setHours(0, 0, 0, 0) !==
                    new Date().setHours(0, 0, 0, 0)
                  ) {
                    loadMulipleDateApi([{ deviceId: deviceId }], dateA);
                    if (id === "M240002") {
                      loadDataofDivice2Day("M250001", dateA);
                    }
                  } else {
                    loadMulipleApi([{ deviceId: deviceId }]);
                    if (id === "M240002") {
                      loadDataofDivice2("M250001");
                    }
                  }
                }}
              >
                Get Details
              </Button>
            </Grid>
          </>
        )}
      </Grid>
      {login.userName === "aliste" && (
        <Row className="mt-5 ">
          <Col md={3} style={{ textAlign: "left" }}>
            <Form.Label htmlFor="inputPassword5">Start Date</Form.Label>
            <Form.Control
              type="datetime-local"
              id="inputPassword5"
              aria-describedby="passwordHelpBlock"
              value={details.startTime}
              onChange={(e) =>
                setDetails({ ...details, startTime: e.target.value })
              }
            />
          </Col>
          <Col md={3} style={{ textAlign: "left" }}>
            <Form.Label htmlFor="inputPassword5">End Date</Form.Label>
            <Form.Control
              type="datetime-local"
              id="inputPassword5"
              aria-describedby="passwordHelpBlock"
              value={details.endTime}
              onChange={(e) =>
                setDetails({ ...details, endTime: e.target.value })
              }
            />
          </Col>
          <Col md={3} style={{ textAlign: "left" }}>
            <Form.Label htmlFor="inputPassword5">Device Id</Form.Label>
            <Form.Control
              type="text"
              id="inputPassword5"
              aria-describedby="passwordHelpBlock"
              value={details.deviceId}
              onChange={(e) =>
                setDetails({ ...details, deviceId: e.target.value })
              }
            />
          </Col>
          <Col md={3} style={{ textAlign: "left" }}>
            <Button
              className="mt-4"
              variant="contained"
              onClick={() =>
                loadMulipleWithStartEndTimeApi(
                  [{ deviceId: details.deviceId }],
                  details.startTime,
                  details.endTime
                )
              }
            >
              Get Data
            </Button>
          </Col>
        </Row>
      )}

      {mulipleData.length !== 0 && (
        <Row style={{ ...styles.smallBoxContainer }} className="row g-4">
          {id !== "total" && (
            <>
              {/* <DetailContainer
                name={"Voltage"}
                value={`${(mulipleData?.length === 1 &&
                Object.keys(mulipleData[0]).length !== 0 &&
                mulipleData[0]?.loadLogs?.length !== 0
                  ? mulipleData[0].loadLogs[mulipleData[0].loadLogs.length - 1]
                      .v
                  : 0
                ).toFixed(2)} V`}
                time={
                  mulipleData[0]?.stateLogs?.length !== 0 &&
                  Object.keys(mulipleData[0]).length !== 0
                    ? mulipleData[0].stateLogs[
                        mulipleData[0].stateLogs.length - 1
                      ].ts
                    : 0
                }
                color={"#FDECEC"}
                borderColor={"#EF4444"}
                image={images.voltage}
              /> */}
              {Object.keys(phaseLoadLogs).length !== 0 &&
                phaseLoadLogs[id] &&
                Object.keys(phaseLoadLogs[id]).map((ph) => {
                  let d = phaseLoadLogs[id][ph].data
                    ? phaseLoadLogs[id][ph].data
                    : [];
                  let unit = phaseLoadLogs[id][ph].unit;
                  return (
                    <>
                      <DetailContainer
                        name={`Voltage ${Number(ph) + 1}`}
                        value={`${(Object.keys(phaseLoadLogs)?.length === 1 &&
                        d.length !== 0
                          ? d[d.length - 1].v
                          : 0
                        ).toFixed(2)} V`}
                        time={
                          Object.keys(phaseLoadLogs)?.length !== 0 &&
                          d.length !== 0
                            ? d[d.length - 1].ts
                            : 0
                        }
                        color={"#FDECEC"}
                        borderColor={"#EF4444"}
                        image={images.voltage}
                      />
                      <DetailContainer
                        name={`Power Factor ${Number(ph) + 1}`}
                        value={`${(Object.keys(phaseLoadLogs)?.length === 1 &&
                        d.length !== 0
                          ? d[d.length - 1].pf
                          : 0
                        ).toFixed(2)}`}
                        time={
                          Object.keys(phaseLoadLogs)?.length !== 0 &&
                          d.length !== 0
                            ? d[d.length - 1].ts
                            : 0
                        }
                        color={"#FDECEC"}
                        borderColor={"#EF4444"}
                        image={images.voltage}
                      />
                      <DetailContainer
                        name={`Power ${Number(ph) + 1}`}
                        value={`${(Object.keys(phaseLoadLogs)?.length === 1 &&
                        d.length !== 0
                          ? d[d.length - 1].p
                          : 0
                        ).toFixed(2)} W`}
                        time={
                          Object.keys(phaseLoadLogs)?.length !== 0 &&
                          d.length !== 0
                            ? d[d.length - 1].ts
                            : 0
                        }
                        color={"#FDECEC"}
                        borderColor={"#EF4444"}
                        image={images.voltage}
                      />
                      <DetailContainer
                        name={`Unit Consumed ${Number(ph) + 1}`}
                        value={`${unit !== undefined ? unit : 0} kwh`}
                        time={
                          Object.keys(phaseLoadLogs)?.length !== 0 &&
                          d.length !== 0
                            ? d[d.length - 1].ts
                            : 0
                        }
                        color={"rgba(254, 224, 55, 0.20)"}
                        borderColor={"#FEE037"}
                        image={
                          images.unitConsumed !== undefined
                            ? images.unitConsumed
                            : 0
                        }
                      />
                    </>
                  );
                })}
            </>
          )}

          {login.userName !== user[1].userName && id !== "total" && (
            <>
              {/* <DetailContainer
              name={"Power Factor"}
              value={
                Object.keys(mulipleData[0]).length !== 0 &&
                mulipleData[0]?.loadLogs?.length !== 0
                  ? mulipleData[0].loadLogs[
                      mulipleData[0].loadLogs.length - 1
                    ].pf.toFixed(2)
                  : 0
              }
              time={
                Object.keys(mulipleData[0]).length !== 0 &&
                mulipleData[0]?.stateLogs?.length !== 0
                  ? mulipleData[0].stateLogs[
                      mulipleData[0].stateLogs.length - 1
                    ].ts
                  : 0
              }
              color={"#FDECEC"}
              borderColor={"#EF4444"}
              image={images.powerFector}
            /> */}
              {/* {Object.keys(phaseLoadLogs).length !== 0 && phaseLoadLogs[id] &&
                Object.keys(phaseLoadLogs[id]).map((ph) => {
                  let d = phaseLoadLogs[id][ph] ? phaseLoadLogs[id][ph] : [];
                  console.log(d, "ddd");
                  return (
                 
                  );
                })} */}
            </>
          )}
          {!OnlineStatus.includes(login.userName) && (
            <DetailContainer
              name={"Online Status"}
              value={onlineOffline(mulipleData)}
              time={
                Object.keys(mulipleData[0]).length !== 0 &&
                mulipleData[0]?.stateLogs?.length !== 0
                  ? mulipleData[0].stateLogs[
                      mulipleData[0].stateLogs.length - 1
                    ].ts
                  : 0
              }
              color={"rgba(118, 190, 194, 0.10)"}
              borderColor={"#76BEC2"}
              image={images.deviceStatus}
            />
          )}
          {console.log(deviceDetail, "ffdfds")}
          {chartNotShow.includes(login.userName) && (
            <DetailContainer
              name={
                login.userName === user[1].userName
                  ? "Indoor Unit Status"
                  : !applianceHealth.includes(login.userName)
                  ? "AC Status"
                  : "Device Status"
              }
              value={
                mulipleData[0].connected
                  ? deviceDetail.applianceState === 0
                    ? "Off"
                    : "On"
                  : "Offline"
              }
              time={
                Object.keys(mulipleData[0]).length !== 0 &&
                mulipleData[0]?.stateLogs?.length !== 0
                  ? mulipleData[0].stateLogs[
                      mulipleData[0].stateLogs.length - 1
                    ].ts
                  : 0
              }
              color={"rgba(118, 190, 194, 0.10)"}
              borderColor={"#76BEC2"}
              image={images.ac}
              OnClick={() => {
                if (mulipleData.length === 1) {
                  if (
                    new Date(dateA).setHours(0, 0, 0, 0) !==
                    new Date().setHours(0, 0, 0, 0)
                  ) {
                    // messages.success("Date is not toady")
                    return;
                  }
                  if (!mulipleData[0].connected) {
                    messages.notify("Device is Offline");
                    return;
                  }
                  if (deviceDetail.applianceState > 0) {
                    deviceControl(id, 0);
                  } else {
                    deviceControl(id, 100);
                  }
                }
              }}
              styleValue={{ cursor: "pointer" }}
            />
          )}

          {id !== "total" && !applianceHealth.includes(login.userName) && (
            <DetailContainer
              name={"Appliance Health"}
              value={portelStatus.includes(login.userName) ? "Ok" : "Training"}
              time={
                Object.keys(mulipleData[0]).length !== 0 &&
                mulipleData[0]?.stateLogs?.length !== 0
                  ? mulipleData[0].stateLogs[
                      mulipleData[0].stateLogs.length - 1
                    ].ts
                  : 0
              }
              color={"rgba(254, 224, 55, 0.20)"}
              borderColor={"#FEE037"}
              image={images.applianceHealth}
            />
          )}
          {login.userName !== user[1].userName && (
            <DetailContainer
              name={"Total Unit Consumed"}
              value={`${
                Object.keys(globalSavingData).length !== 0
                  ? globalSavingData.globalUnitConsumed.toFixed(2)
                  : 0
              } kwh`}
              time={
                Object.keys(mulipleData[0]).length !== 0 &&
                mulipleData[0]?.stateLogs?.length !== 0
                  ? mulipleData[0].stateLogs[
                      mulipleData[0].stateLogs.length - 1
                    ].ts
                  : 0
              }
              color={"rgba(254, 224, 55, 0.20)"}
              borderColor={"#FEE037"}
              image={
                images.unitConsumed !== undefined ? images.unitConsumed : 0
              }
            />
          )}
          {(!chartNotIncluse.includes(id) &&
            !onlyMoniter.includes(login.userName)) || unitTab.includes(login.userName) && (
              <DetailContainer
                name={"Total Unit Saved"}
                value={`${
                  Object.keys(globalSavingData).length !== 0
                    ? login?.random === true
                      ? (
                          (globalSavingData.globalUnitConsumed *
                            randomSavingNumber) /
                          100
                        ).toFixed(2)
                      : globalSavingData.globalUnitSaved.toFixed(2)
                    : 0
                } kwh`}
                time={
                  Object.keys(mulipleData[0]).length !== 0 &&
                  mulipleData[0]?.stateLogs?.length !== 0
                    ? mulipleData[0].stateLogs[
                        mulipleData[0].stateLogs.length - 1
                      ].ts
                    : 0
                }
                color={"rgba(50, 47, 200, 0.10)"}
                borderColor={"#322FC8"}
                image={images.unit !== undefined ? images.unit : 0}
              />
            )}

          {login.userName !== user[1].userName && !moneySpentNotShow.includes(login.userName) && (
            <DetailContainer
              name={"Total Money Spent"}
              value={`${
                Object.keys(globalSavingData).length !== 0
                  ? globalSavingData.globalMoneySpent.toFixed(2)
                  : 0
              }`}
              time={
                Object.keys(mulipleData[0]).length !== 0 &&
                mulipleData[0]?.stateLogs?.length !== 0
                  ? mulipleData[0].stateLogs[
                      mulipleData[0].stateLogs.length - 1
                    ].ts
                  : 0
              }
              color={"rgba(50, 47, 200, 0.10)"}
              borderColor={"#322FC8"}
              image={images.moneySpent}
            />
          )}

          {/* {login.userName !== user[1].userName && */}
          {!chartNotIncluse.includes(id) &&
            !onlyMoniter.includes(login.userName) && (
              <>
                <DetailContainer
                  name={"Total Money Saved"}
                  value={`${
                    Object.keys(globalSavingData).length !== 0
                      ? login?.random === true
                        ? (
                            (globalSavingData.globalMoneySpent *
                              randomSavingNumber) /
                            100
                          ).toFixed(2)
                        : globalSavingData.globalMoneySaved.toFixed(2)
                      : 0
                  }`}
                  time={
                    Object.keys(mulipleData[0]).length !== 0 &&
                    mulipleData[0]?.stateLogs?.length !== 0
                      ? mulipleData[0].stateLogs[
                          mulipleData[0].stateLogs.length - 1
                        ].ts
                      : 0
                  }
                  color={"rgba(50, 47, 200, 0.10)"}
                  borderColor={"#322FC8"}
                  image={
                    images.moneySaved !== undefined ? images.moneySaved : 0
                  }
                />
              </>
            )}

        
                        {login.userName !== user[1].userName &&
            !chartNotIncluse.includes(id) &&
            !onlyMoniter.includes(login.userName) && (
                <DetailContainer
                  name={"Money Saving"}
                  value={`${
                    Object.keys(globalSavingData).length !== 0
                      ? `${
                          isNaN(
                            (login?.random === true
                              ? (globalSavingData.globalMoneySpent *
                                  randomSavingNumber) /
                                100
                              : globalSavingData.globalMoneySaved) /
                              (globalSavingData.globalMoneySpent +
                                (login?.random === true
                                  ? (globalSavingData.globalMoneySpent *
                                      randomSavingNumber) /
                                    100
                                  : globalSavingData.globalMoneySaved))
                          )
                            ? "0"
                            : (
                                ((login?.random === true
                                  ? (globalSavingData.globalMoneySpent *
                                      randomSavingNumber) /
                                    100
                                  : globalSavingData.globalMoneySaved) /
                                  (globalSavingData.globalMoneySpent +
                                    (login?.random === true
                                      ? (globalSavingData.globalMoneySpent *
                                          randomSavingNumber) /
                                        100
                                      : globalSavingData.globalMoneySaved))) *
                                100
                              ).toFixed(2)
                        } %`
                      : 0
                  }`}
                  time={
                    Object.keys(mulipleData[0]).length !== 0 &&
                    mulipleData[0].stateLogs?.length !== 0
                      ? mulipleData[0].stateLogs[
                          mulipleData[0].stateLogs.length - 1
                        ].ts
                      : 0
                  }
                  color={"rgba(50, 47, 200, 0.10)"}
                  borderColor={"#322FC8"}
                  image={images.coin !== undefined ? images.coin : 0}
                />)
                }
                 {((login.userName !== user[1].userName &&
            !chartNotIncluse.includes(id) &&
            !onlyMoniter.includes(login.userName)) || unitTab.includes(login.userName)) && (
                <DetailContainer
                  name={"Unit Saving"}
                  value={`${
                    Object.keys(globalSavingData).length !== 0
                      ? `${
                          isNaN(
                            (login?.random === true
                              ? (globalSavingData.globalUnitConsumed *
                                  randomSavingNumber) /
                                100
                              : globalSavingData.globalUnitSaved) /
                              (globalSavingData.globalUnitConsumed +
                                (login?.random === true
                                  ? (globalSavingData.globalUnitConsumed *
                                      randomSavingNumber) /
                                    100
                                  : globalSavingData.globalUnitSaved))
                          )
                            ? "0"
                            : (
                                ((login?.random === true
                                  ? (globalSavingData.globalUnitConsumed *
                                      randomSavingNumber) /
                                    100
                                  : globalSavingData.globalUnitSaved) /
                                  (globalSavingData.globalUnitConsumed +
                                    (login?.random === true
                                      ? (globalSavingData.globalUnitConsumed *
                                          randomSavingNumber) /
                                        100
                                      : globalSavingData.globalUnitSaved))) *
                                100
                              ).toFixed(2)
                        } %`
                      : 0
                  }`}
                  time={
                    Object.keys(mulipleData[0]).length !== 0 &&
                    mulipleData[0].stateLogs?.length !== 0
                      ? mulipleData[0].stateLogs[
                          mulipleData[0].stateLogs.length - 1
                        ].ts
                      : 0
                  }
                  color={"rgba(50, 47, 200, 0.10)"}
                  borderColor={"#322FC8"}
                  image={
                    images.unitConsumed !== undefined ? images.unitConsumed : 0
                  }
                />
            )}
             {login.userName !== user[1].userName &&
            !chartNotIncluse.includes(id) &&
            !onlyMoniter.includes(login.userName) && (
                <DetailContainer
                  name={"Carbon Saving"}
                  value={`${
                    Object.keys(globalSavingData).length !== 0
                      ? `${
                          isNaN(
                            (login?.random === true
                              ? (totalCarbonConsume(
                                  globalCarbonConsumeChartData
                                ) *
                                  randomSavingNumber) /
                                100
                              : totalCarbonConsume(
                                  globalCarbonSavedChartData
                                )) /
                              (totalCarbonConsume(
                                globalCarbonConsumeChartData
                              ) +
                                (login?.random === true
                                  ? (totalCarbonConsume(
                                      globalCarbonConsumeChartData
                                    ) *
                                      randomSavingNumber) /
                                    100
                                  : totalCarbonConsume(
                                      globalCarbonSavedChartData
                                    )))
                          )
                            ? "0"
                            : (
                                ((login?.random === true
                                  ? (totalCarbonConsume(
                                      globalCarbonConsumeChartData
                                    ) *
                                      randomSavingNumber) /
                                    100
                                  : totalCarbonConsume(
                                      globalCarbonSavedChartData
                                    )) /
                                  (totalCarbonConsume(
                                    globalCarbonConsumeChartData
                                  ) +
                                    (login?.random === true
                                      ? (totalCarbonConsume(
                                          globalCarbonConsumeChartData
                                        ) *
                                          randomSavingNumber) /
                                        100
                                      : totalCarbonConsume(
                                          globalCarbonSavedChartData
                                        )))) *
                                100
                              ).toFixed(2)
                        } %`
                      : 0
                  }`}
                  time={
                    Object.keys(mulipleData[0]).length !== 0 &&
                    mulipleData[0].stateLogs?.length !== 0
                      ? mulipleData[0].stateLogs[
                          mulipleData[0].stateLogs.length - 1
                        ].ts
                      : 0
                  }
                  color={"rgba(26, 200, 33, 0.10)"}
                  borderColor={"#322FC8"}
                  image={
                    images.carbonFootPrint !== undefined
                      ? images.carbonFootPrint
                      : 0
                  }
                />
            )}
          {id !== "total" &&
            autoCutShow.includes(login.userName) &&
            Object.keys(deviceDetail).length !== 0 &&
            deviceDetail.hasOwnProperty("autoTimers") && (
              <DetailContainer
                name={"Auto Cut"}
                value={
                  Object.keys(deviceDetail).length === 0
                    ? ""
                    : deviceDetail.hasOwnProperty("autoTimers")
                    ? deviceDetail.autoTimers.enabled
                      ? "On"
                      : "Off"
                    : ""
                }
                time={
                  Object.keys(mulipleData[0]).length !== 0 &&
                  mulipleData[0]?.stateLogs?.length !== 0
                    ? mulipleData[0].stateLogs[
                        mulipleData[0].stateLogs.length - 1
                      ].ts
                    : 0
                }
                color={"rgba(118, 190, 194, 0.10)"}
                borderColor={"#76BEC2"}
                image={images.smartService}
                OnClick={() => {
                  if (
                    new Date(dateA).setHours(0, 0, 0, 0) !==
                    new Date().setHours(0, 0, 0, 0)
                  ) {
                    messages.success("Date is not toady");
                    return;
                  }
                  if (deviceDetail.autoTimers.enabled) {
                    autoTimerStop(id);
                  } else if (!deviceDetail.autoTimers.enabled) {
                    autoTimersStart(id);
                  }
                }}
                styleValue={{ cursor: "pointer" }}
              />
            )}
        </Row>
      )}
      {mulipleData.length !== 0 && (
        <Row
          style={{
            marginBottom: "20px",
          }}
        >
          {/* {login.userName !== user[2].userName && login.userName !== "campus" && id!=="total" && (
            <Col md={6} sm={12}>
              <div style={{ ...styles.chartContainer }}>
                <ChartHeader
                  name={"On Time"}
                  subName={onTime[0]}
                  image={images.onTime}
                  textColor={"#1AC821"}
                  iconBackgroundColor={"rgba(26, 200, 33, 0.10)"}
                />
                <div
                  style={{
                    display: "flex",
                    height: "215px",
                    alignItems: "center",
                    justifyContent: "spacebetween",
                  }}
                >
                  <div>
                    <PieChart
                      seriesData={onTime}
                      labels={["On-Time", "Off-Time"]}
                      size={215}
                    />
                  </div>
                  <div
                    style={{
                      display: "flex",
                      gap: "22px",
                      flexDirection: "column",
                    }}
                  >
                    <div style={styles.smallPieContainer}>
                      <SingleValuePieChart
                        data={chartData.OnTime}
                        color={"#322FC8"}
                      />
                      <TextContainer
                        header={"On time"}
                        subHeader={
                          "That’s Why We provide point and click solution"
                        }
                      />
                    </div>
                    <div style={styles.smallPieContainer}>
                      <SingleValuePieChart
                        data={chartData.OffTime}
                        color={"#191F2F"}
                      />
                      <TextContainer
                        header={"Off Time"}
                        subHeader={
                          "That’s Why We provide point and click solution"
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          )} */}
          {/* {
          id!=="M240025" && ( */}
          {!chartNotIncluse.includes(id) &&
            !onlyMoniter.includes(login.userName) &&
            !randomSaving.includes(login.userName) && (
              <Col md={6} sm={12}>
                <div
                  style={{
                    ...styles.chartContainer,
                    ...{ height: id === "total" && "max-content" },
                  }}
                >
                  <ChartHeader
                    name={"Carbon footprint saved"}
                    subName={`${totalCarbonConsume(
                      globalCarbonSavedChartData
                    ).toFixed(2)}  kg CO2`}
                    image={images.carbonFootPrint}
                    textColor={"#1AC821"}
                    iconBackgroundColor={"rgba(26, 200, 33, 0.10)"}
                  />
                  <CarbonConsumeBarChart
                    barDatas={globalCarbonSavedChartData}
                  />
                </div>
              </Col>
            )}
          {unitGraph.includes(login.userName) && (
            <Col md={6} sm={12}>
              <div
                style={{
                  ...styles.chartContainer,
                  ...{ height: id === "total" && "max-content" },
                }}
              >
                <ChartHeader
                  name={"Unit saved"}
                  subName={`${totalCarbonConsume(
                    globalUnitNewSavedChartData
                  ).toFixed(2)}  kwh`}
                  image={images.carbonFootPrint}
                  textColor={"#1AC821"}
                  iconBackgroundColor={"rgba(26, 200, 33, 0.10)"}
                />
                <CarbonConsumeBarChart barDatas={globalUnitNewSavedChartData} />
              </div>
            </Col>
          )}
          {/* )
         } */}

          {globalMoneySavedChartData.length !== 0 &&
            login.userName === user[1].userName && (
              <Col md={6} sm={12}>
                <div style={{ ...styles.chartContainer }}>
                  <ChartHeader
                    name={"Money Saved"}
                    subName={moneySavedChart(globalMoneySavedChartData)}
                    image={images.moneySaved}
                    textColor={"#1AC821"}
                    iconBackgroundColor={"rgba(26, 200, 33, 0.10)"}
                  />
                  <MoneySavedBar barDatas={globalMoneySavedChartData} />
                </div>
              </Col>
            )}

          {chartShow.includes(login.userName) && (
            <Col md={6} sm={12}>
              <div
                style={{
                  ...styles.chartContainer,
                  ...{ height: id === "total" && "max-content" },
                }}
              >
                <ChartHeader
                  name={"Carbon footprint Consumed"}
                  subName={`${totalCarbonConsume(
                    globalCarbonConsumeChartData
                  ).toFixed(2)}  kg CO2`}
                  image={images.carbonFootPrint}
                  textColor={"#1AC821"}
                  iconBackgroundColor={"rgba(26, 200, 33, 0.10)"}
                />
                <CarbonConsumeBarChart
                  barDatas={globalCarbonConsumeChartData}
                />
              </div>
            </Col>
          )}
          {unitGraph.includes(login.userName) && (
            <Col md={6} sm={12}>
              <div
                style={{
                  ...styles.chartContainer,
                  ...{ height: id === "total" && "max-content" },
                }}
              >
                <ChartHeader
                  name={"Unit Consumed"}
                  subName={`${totalCarbonConsume(
                    globalUnitNewConsumeChartData
                  ).toFixed(2)} kwh`}
                  image={images.carbonFootPrint}
                  textColor={"#1AC821"}
                  iconBackgroundColor={"rgba(26, 200, 33, 0.10)"}
                />
                <CarbonConsumeBarChart
                  barDatas={globalUnitNewConsumeChartData}
                />
              </div>
            </Col>
          )}
          {id !== "total" &&
            !chartNotIncluse.includes(id) &&
            !temperatureDataNotShow.includes(login.userName) &&
            !randomSaving.includes(login.userName) && (
              <Col md={6} sm={12}>
                <div style={{ ...styles.chartContainer }}>
                  <ChartHeader
                    name={`${
                      chartShow.includes(login.userName) ? "Current" : ""
                    } Temperature`}
                    subName={`${
                      mulipleData.length === 1 &&
                      Object.keys(mulipleData[0]).length !== 0 &&
                      mulipleData[0]?.temperatureLogs?.length !== 0
                        ? mulipleData[0].temperatureLogs[
                            mulipleData[0].temperatureLogs.length - 1
                          ].t.toFixed(2)
                        : 0
                    }°c`}
                    image={images.temperture}
                    textColor={"#322FC8"}
                    iconBackgroundColor={"rgba(50, 47, 200, 0.10)"}
                  />
                  <LineChart
                    seriesData={globalTemperatureChartData}
                    degree={"Temperature"}
                  />
                </div>
              </Col>
            )}
          {id !== "total" && login.userName === "stanza" && (
            <Col md={6} sm={12}>
              <div style={{ ...styles.chartContainer }}>
                <ChartHeader
                  name={`Unit Consumed`}
                  subName={`${totalCarbonConsume(
                    globalUnitConsumedLine
                  ).toFixed(2)} kwh`}
                  image={images.temperture}
                  textColor={"#322FC8"}
                  iconBackgroundColor={"rgba(50, 47, 200, 0.10)"}
                />
                <IntervlLineChart
                  seriesData={globalUnitConsumedLine}
                  degree={"Unit"}
                />
              </div>
            </Col>
          )}
          {id !== "total" && (
            <Col md={6} sm={12}>
              <div style={{ ...styles.chartContainer }}>
                <ChartHeader
                  name={`${
                    login.userName === user[1].userName
                      ? "Indoor Unit"
                      : chartShow.includes(login.userName)
                      ? "RealTime"
                      : ""
                  } Power Consumption`}
                  subName={totalPhasePowerConsumption(phaseLoadLogs)}
                  image={images.powerCompunsion}
                  textColor={"#322FC8"}
                  iconBackgroundColor={"rgba(50, 47, 200, 0.10)"}
                />
                <LineChart
                  seriesData={globalPowerConsumtionChartData}
                  degree={"Power"}
                />
              </div>
            </Col>
          )}

          {Object.keys(toadyData2).length !== 0 &&
            id !== "total" &&
            login.userName === user[1].userName && (
              <Col md={6} sm={12}>
                <div style={{ ...styles.chartContainer }}>
                  <ChartHeader
                    name={`${
                      login.userName === user[1].userName
                        ? "OutDoor Unit"
                        : chartShow.includes(login.userName)
                        ? "RealTime"
                        : ""
                    } Power Consumption`}
                    subName={`${
                      toadyData2.loadLogs.length !== 0
                        ? toadyData2.loadLogs[
                            toadyData2.loadLogs.length - 1
                          ].p.toFixed(2)
                        : 0
                    } W`}
                    image={images.powerCompunsion}
                    textColor={"#322FC8"}
                    iconBackgroundColor={"rgba(50, 47, 200, 0.10)"}
                  />
                  <LineChart seriesData={[power2]} degree={"Power1"} />
                </div>
              </Col>
            )}
        </Row>
      )}
    </div>
  );
}

export default MainV2;
