import dashBoardIcon from '../../assets/svg/dashboard_icon.svg'
import React from 'react'

function DasbordIcon({activ={},d={}}) {
    console.log(d,"show name");
    return (
        <div style={activ}>
            <svg width="34" height="37" viewBox="0 0 34 37" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g id="&#240;&#159;&#166;&#134; icon &#34;dashboard&#34;">
                    <path id="Vector" d="M1.85058 20.4067H12.9541C13.9756 20.4067 14.8046 19.4945 14.8046 18.366V2.04067C14.8046 0.912179 13.9756 0 12.9541 0H1.85058C0.82906 0 0 0.912179 0 2.04067V18.366C0 19.4945 0.82906 20.4067 1.85058 20.4067ZM0 34.6914C0 35.8199 0.82906 36.732 1.85058 36.732H12.9541C13.9756 36.732 14.8046 35.8199 14.8046 34.6914V26.5287C14.8046 25.4002 13.9756 24.488 12.9541 24.488H1.85058C0.82906 24.488 0 25.4002 0 26.5287V34.6914ZM18.5058 34.6914C18.5058 35.8199 19.333 36.732 20.3564 36.732H31.4599C32.4832 36.732 33.3104 35.8199 33.3104 34.6914V20.4067C33.3104 19.2782 32.4832 18.366 31.4599 18.366H20.3564C19.333 18.366 18.5058 19.2782 18.5058 20.4067V34.6914ZM20.3564 14.2847H31.4599C32.4832 14.2847 33.3104 13.3725 33.3104 12.244V2.04067C33.3104 0.912179 32.4832 0 31.4599 0H20.3564C19.333 0 18.5058 0.912179 18.5058 2.04067V12.244C18.5058 13.3725 19.333 14.2847 20.3564 14.2847Z" fill="#2050FF" />
                </g>
            </svg>
            {
                d.showName!==undefined && <div style={{lineHeight: "initial"}}>{d.showName}</div>
            }
            
        </div>
    )
}

export default DasbordIcon