import React, { useEffect, useRef, useState } from 'react'
import { CaretDownFill } from 'react-bootstrap-icons';
import { useDispatch, useSelector } from 'react-redux';
import {useNavigate } from 'react-router-dom';
import { loginDetail } from '../redux/action/login';

function Header() {
    const [pop,setPop] = useState(false)
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const login = useSelector(state=>state.login)
    useEffect(()=>{
        if(Object.keys(login).length===0){
           navigate('/')
        }else if(Object.keys(login).length!==0){
            if(Array.isArray(login.deviceId)){
                if(login.deviceId.length===0){
                    dispatch(loginDetail({}))
                    navigate('/')
                }
            }
        }
    },[login])
    const ref = useRef()
    const style = {
        headerContainer: {
            height: '10vh',
            width: '100%',
            backgroundColor: '#FFFFFF',
            display: 'flex',

            justifyContent: "space-between",
            alignItems: "center",
        },
        welcome: {
            color: "#212529",
            fontFamily: "manrope",
            fontSize: "22px",
            fontStyle: "normal",
            fontWeight: 600,
            lineHeight: "24px",
            marginLeft: '10px'
        },
        login: {
            color: "#000",
            fontFamily: 'manrope',
            fontSize: "16px",
            fontStyle: "normal",
            fontWeight: 400,
            lineHeight: "24px",
            marginRight:'8px'
        }
    }
    useEffect(() => {
        /**
         * Alert if clicked on outside of element
         */
        function handleClickOutside(event) {
          if (ref.current && !ref.current.contains(event.target)) {
          
            setPop(false)
          }
        }
        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
          // Unbind the event listener on clean up
          document.removeEventListener("mousedown", handleClickOutside);
        };
      }, [ref]);
    return (
        <div style={{ ...style.headerContainer }}>
            <div style={{ ...style.welcome }}>
                Welcome Back, {login.userName}
            </div>
            <div style={{
                marginRight:'30px',
                display: "flex",
                alignItems: "center",
                position:'relative'
                }}>
                <span style={{...style.login}}>{login.userName}</span>
                <CaretDownFill onClick={()=>setPop(true)}/>
                {
                 pop && <div ref={ref} style={{backgroundColor:'#fff',borderRadius:'16px',padding:'10px',position:'absolute',bottom:'-60px',right:'10px'}}>
                        <div style={{...style.login,cursor:'pointer',color:'rgb(255 22 22 / 81%)'}}
                          onClick={()=>dispatch(loginDetail({}))}
                        >Logout</div>
                    </div>
                }
            </div>
        </div>
    )
}

export default Header